<template>
  <div class="container">
      <h2>Stores List</h2>
      <b-row class="justify-content-center">
        <b-card-group deck  v-for="(storesRow, index) in this.groupedStores" :key="'row-'+index" >
          <b-card v-for="store in storesRow" v-bind:key="store.id" class="mb-3 store-card" no-body img-alt="Store" img-top>
              <b-card-img-lazy height="200" :src="envAPI + 'images/get_store_image?id='+store.store_deals">
              </b-card-img-lazy>
              <b-card-body  class="w-100" style="font-weight:bold; text-align:center;background-color: #e6e6e6;">
                <a class="bold" v-bind:href="'deals/_/'+store.store_deals+'/_'">{{store.name}}</a>
              </b-card-body>
          </b-card>
        </b-card-group>
      </b-row>
  </div>

</template>

<script>
import Api from '@/services/Api'
export default {
  name: 'StoresList',
  data () {
    return {
      storesData: { numberOfStores: 0, stores: [] },
      numberOfStores: 0,
      currentPage: 1,
      storesPerPage: 8
    }
  },
  mounted () {
    this.getStores()
  },
  computed: {
    user: {
      get () {
        return this.$store.state.User.user
      }
    },
    envAPI: function (params) {
      return process.env.VUE_APP_API_ENDPOINT
    },
    groupedStores: {
      get () {
        if (this.storesData.stores.length > 0) {
          return this.storesData.stores.reduce((c, n, i) => {
            if (i % 4 === 0) c.push([])
            c[c.length - 1].push(n)
            return c
          }, [])
        } else {
          return []
        }
      }
    },
    shouldShowAuth: {
      get () {
        return this.$store.state.Global.shouldShowAuth
      },
      set (visibility) {
        this.$store.commit('SET_AUTH_DIALOG_VISIBILITY', visibility)
      }
    }
  },
  watch: {
    currentPage: function () {
      this.getStores()
    }
  },
  methods: {
    getStores: function () {
      var self = this
      Api()
        .get('/stores/get_stores', { params: { start: (this.currentPage - 1) * this.storesPerPage, limit: this.storesPerPage } })
        .then((data) => {
          self.storesData = data.data
        })
    }
  },
  mixins: []
}
</script>

<style scoped>

</style>
