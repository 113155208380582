
import Api from '@/services/Api'

export default {

  getAllStores (context) {
    return new Promise((resolve, reject) => {
      Api()
        .get('/stores/all_stores', { params: { } })
        .then(({ data }) => {
          // console.log(data)
          context.commit('SET_ALL_STORES', data)
          resolve(data)
        })
        .catch(error => reject(error))
    })
  },
  searchForStore (context, data) {
    return new Promise((resolve, reject) => {
      Api()
        .get('/stores/keyword_filtered_stores', { params: { keyword: data.keyword } })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => reject(error))
    })
  },
  getFilteredStores (context, data) {
    return new Promise((resolve, reject) => {
      Api()
        .get('/stores/filtered_stores', { params: {
          ...data.keyword !== undefined && { keyword: data.keyword },
          // ...data.id !== undefined && { id: data.id },
          ...(data.categories !== undefined && data.categories !== {}) && { categories: data.categories } } })
        .then(({ data }) => {
          // console.log(data)
          data = data.rows
          context.commit('SET_FILTERED_STORES', data)
          // console.log(data)
          resolve(data)
        })
        .catch(error => reject(error))
    })
  }

}
