<template>
  <div>
    <b-table :fields='tableFields' striped hover :items='formattedUsers'>
        <template #cell(actions)="row">
            <a size="sm" href="#" @click.prevent="toggleUserStatus(row)" class="mr-2">
                <font-awesome-icon :icon="row.item.status == 'ACTIVE'? ['far','stop-circle']:['far','play-circle']" />
            </a>
        </template>
        <template #cell(role)="row">
            <a v-b-modal.modal-1 size="sm" href="#" @click.prevent="editUserRole(row.item)" class="mr-2">
                {{row.item.role}}
            </a>
        </template>
    </b-table>
    <b-row class="justify-content-md-center">
        <b-pagination
        v-model="page"
        :total-rows="totalUsers"
        :per-page="usersPerPage"
        aria-controls="my-table"
        ></b-pagination>
    </b-row>
  <b-modal id="modal-1" title="BootstrapVue">
    <p>{{editedUser}}</p>
    <b-form-select v-model="editedUser.role" :options="rolesOptions"></b-form-select>
    <p class="my-4">Hello from modal!</p>
  </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Modal from '@/components/widgets/Modal.vue'
export default {
  name: 'UsersAdmin',
  components: {
    // eslint-disable-next-line no-undef
    // eslint-disable-next-line vue/no-unused-components
    Modal
  },
  props: {
    msg: String
  },
  data: function () {
    return {
      users: [],
      totalUsers: 0,
      formattedUsers: [],
      editedUser: {},
      page: 1,
      usersPerPage: 10,
      rolesOptions: [
        { value: 'REGULAR', text: 'Regular User' },
        { value: 'MODERATOR', text: 'Moderator' },
        { value: 'ADMIN', text: 'ADMIN' },
        { value: 'SUPER_ADMIN', text: 'Super Admin' }
      ],
      tableFields: ['first_name', 'last_name', 'role', 'status', 'email_status', 'email', 'username', 'actions']
    }
  },
  watch: {
    page: function (page) {
      this.getUsers({ page: this.page, count: this.usersPerPage }).then((data) => {
        this.users = data.users
        this.totalUsers = data.count
      })
    },
    users: {
      deep: true,
      handler () {
        // console.log('users changed')
        this.formattedUsers = this.users.map(function (item) {
          return {
            first_name: item.firstName,
            last_name: item.lastName,
            role: item.role,
            status: item.status,
            email_status: item.emailStatus,
            email: item.emailAddress,
            username: item.displayName
          }
        })
      }

    }

  },
  mounted: function (params) {
    this.getUsers({ page: this.page, count: this.usersPerPage }).then((data) => {
      this.users = data.users
      this.totalUsers = data.count
    })
  },
  methods: {
    ...mapActions(['getUsers', 'activateDeactivateUsers']),
    toggleUserStatus: function (item) {
      // console.log(item)
      var user = this.users[item.index]
      var status = 0
      if (user.status === 'DISABLED') { status = 1 } else if (user.status === 'ACTIVE') { status = 0 }
      this.activateDeactivateUsers({ userID: user.id, status: status }).then((data) => {
        if (data.status === 200) {
          // console.log(status)
          var newStatus = status === 1 ? 'ACTIVE' : 'DISABLED'
          // console.log(newStatus)
          // console.log(this.users[item.index].status)
          this.users[item.index].status = newStatus
          // console.log(this.users[item.index].status)
          // this.users.push({ test: 'test' })
        }
      })
    },
    editUserRole (user) {
      this.editedUser = this.users.find((item) => item.emailAddress === user.email)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
