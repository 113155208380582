<template>
  <aside class="sidebar-shop sidebar-search col-lg-3 pt-3  order-lg-first filter-mobile " v-bind:class="{'filter-mobile-opened': mobileFilterBarShown}">
    <div>
      <div class="sidebar-wrapper" style="border-bottom: 0px none rgb(122, 125, 130); ">
        <b-row class="mx-0">
              <b-col cols="8" class="px-1"><h3 class="my-0 mx-2">Filters</h3></b-col>
              <b-col cols="4" class="px-1 close-filter-bar-widget"><a class="toggle-mobile-filter" href="#" @click="toggleMobileFilterBar">[x] close</a></b-col>
        </b-row>
        <b-row v-if="!(globalFilter.keywordFilter)" class="mx-0 p-2">
              <b-col cols="10" class="p-0">
                <b-form-input size="sm" class="mr-sm-2" placeholder="Keyword" v-model="keyword"></b-form-input>
              </b-col>
              <b-col cols="2" class="p-0 pl-1">
                <b-button size="sm" @click.prevent="applyFilters">
                  <font-awesome-icon :icon="['fas', 'search']"/>
                </b-button>
              </b-col>
        </b-row>
        <b-row v-if="globalFilter.keywordFilter" v-on:click.prevent="resetFilter('KEYWORD')" class="mx-0 p-2">
          <b-col cols="12" class="px-0">
            <b-card>
              <template #header>
                Keyword
              </template>
              <b-button>{{globalFilter.keywordFilter}} [x]</b-button>
            </b-card>
          </b-col>
        </b-row>
        <b-card class="my-4" no-body>
          <template #header>
            <b-container class="px-0">
              <b-row>
                <b-col cols="8" class="px-1"><b-card-title class="widget-title">Categories</b-card-title></b-col>
                <b-col cols="4" class="px-1"><b-button v-b-toggle.collapse-categories class="float-right" @click="categoriesCollapsed=!categoriesCollapsed" size="sm"><b>{{categoriesCollapsed?'+':'-'}}</b></b-button></b-col>
              </b-row>
            </b-container>
          </template>
          <b-collapse id="collapse-categories" visible class="filter-box">
              <treeselect
                placeholder="Search Categories"
                :load-options="loadOptions"
                :auto-load-root-options="true"
                :options="treeCategories"
                :openDirection="'below'"
                :multiple="true"
                :always-open="true"
                :shouldShowCount="true"
                id="deal-category" v-model="selectedCategories"  :append-to-body="false"  >
                  <label slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName">
                    {{ node.raw.label }}
                    <span>({{ node.raw.numberOfDeals }})</span>
                  </label>
                </treeselect>
          </b-collapse>
          <template #footer v-if="!categoriesCollapsed">
            <div class="filter-buttons mt-1"><b-button @click.prevent="applyFilters" variant="primary" class="apply-btn p-2 m-2" size="sm">Apply</b-button><b-button variant="primary" @click.prevent="resetFilter('CATEGORIES')" class="p-2 m-2 reset-btn" size="sm">Reset</b-button></div>
          </template>
        </b-card>
        <b-card class="my-4" no-body>
          <template #header>
            <b-container class="px-0">
              <b-row>
                <b-col cols="8" class="px-1"><b-card-title class="widget-title">Stores</b-card-title></b-col>
                <b-col cols="4" class="px-1"><b-button v-b-toggle.collapse-stores class="float-right" @click="storesCollapsed=!storesCollapsed" size="sm"><b>{{storesCollapsed?'+':'-'}}</b></b-button></b-col>
              </b-row>
            </b-container>
          </template>
          <!-- <button v-if="storeFilter.id" class="btn btn-light btn-sm" v-on:click.prevent="removeStoreFilter(storeFilter.id)" style="border: 1px solid #e2e2e2;">{{storeFilter.name}}<span style="margin-left: 10px"><font-awesome-icon :icon="['far', 'minus-square']" /></span></button> -->
          <b-collapse visible class="filter-box" id="collapse-stores">
            <div class="widget-body stores-filter-container p-2">
              <b-form-group v-if="filteredStores.length > 0">
                <b-form-checkbox-group
                  v-model="selectedStores"
                  plain
                  stacked
                  ><b-form-checkbox
                      v-for="option in filteredStores"
                      :key="option.id"
                      :value="option.id"
                    >
                      {{ option.name }}
                    </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div><!-- End .widget-body -->
          </b-collapse><!-- End .collapse -->
          <template #footer v-if="!storesCollapsed">
            <div class="filter-buttons mt-1"><b-button variant="primary" @click.prevent="applyFilters" class="apply-btn p-2 m-2" size="sm">Apply</b-button><b-button variant="primary"  class="p-2 m-2 reset-btn" @click.prevent="resetFilter('STORES')" size="sm">Reset</b-button></div>
          </template>
        </b-card>
      </div>
    </div><!-- End .sidebar-wrapper -->
  </aside>
</template>

<script>
// import the component
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import { LOAD_CHILDREN_OPTIONS, LOAD_ROOT_OPTIONS, Treeselect } from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

/* const getCookie = name => {
  const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  return cookies ? cookies[1] : ''
} */

export default {
  components: { Treeselect },
  name: 'FiltersColumn',
  validations: {
    categoryFilter: {
      required
    },
    store: {
    },
    selectedCategories: {
    }
  },
  data () {
    return {
      loading: false,
      navigateTo: 0,
      keyword: '',
      called: false,
      filteredTreeCategories: null,
      selectedCategories: [],
      treeCategories: [],
      selectedStores: [],
      categoriesCollapsed: false,
      storesCollapsed: false,
      keywordCollapsed: false
    }
  },
  created () {

  },
  mounted () {
    var routeCategories = this.$router.currentRoute.params.category
    routeCategories = routeCategories === '_' ? [] : routeCategories.split(',')
    this.selectedCategories = routeCategories

    // eslint-disable-next-line no-unused-vars
    var routeStores = this.$router.currentRoute.params.store
    routeStores = routeStores === '_' ? [] : routeStores.split(',')
    this.selectedStores = routeStores
    // eslint-disable-next-line no-unused-vars
    var routeKeyword = this.$router.currentRoute.params.keyword
    routeKeyword = routeKeyword === '_' ? '' : routeKeyword
    this.keyWord = routeKeyword
    this.applyFilters()
  },
  watch: {
    filteredCategories: function (newCats, oldCats) {
      this.treeCategories = this.filteredCategories
      if (!this.called) {
      }
    }
  },
  computed: {
    globalFilter: {
      get () {
        // console.log(this.$store.state.Global.globalFilter)
        return this.$store.state.Filters.globalFilter ? this.$store.state.Filters.globalFilter : {}
      }
    },
    mobileFilterBarShown: {
      get () {
        // console.log(this.$store.state.Global.mobileFilterBarShown)
        return this.$store.state.Global.mobileFilterBarShown
      }
    },
    filteredCategories: {
      get () {
        // console.log(this.$store.state.Categories.filteredCategories)
        if (this.$store.state.Categories.filteredCategories) { return this.$store.state.Categories.filteredCategories } else return {}
      }
    },
    filteredStores: {
      get () {
        // console.log(this.$store.state.Stores.filteredStores)
        if (this.$store.state.Stores.filteredStores) { return this.$store.state.Stores.filteredStores } else return {}
      }
    },
    keywordFilter: {
      get () {
        // console.log(this.$store.state.Filters.keywordFilter)
        return this.$store.state.Filters.keywordFilter
      },
      set (keyword) {
        this.$store.commit('SET_KEYWORD_FILTER', keyword)
      }
    },
    storeFilter: {
      get () {
        // console.log(this.$store.state.Filters.storeFilter)
        return this.$store.state.Filters.storeFilter
      },
      set (store) {
        this.$store.commit('SET_STORE_FILTER', store)
      }
    },
    categoryFilter: {
      get () {
        // console.log(this.$store.state.Filters.categoryFilter)
        return this.$store.state.Filters.categoryFilter
      },
      set (category) {
        this.$store.commit('SET_CATEGORY_FILTER', category)
      }
    },
    newDealCategories: {
      get () {
        // console.log(this.$store.state.Categories.newDealCategories)
        if (this.$store.state.Categories.newDealCategories) { return this.$store.state.Categories.newDealCategories } else return {}
      }
    }
  },
  methods: {
    ...mapActions({
      removeCategoryFilter: 'removeCategoryFilter',
      removeKeywordFilter: 'removeKeywordFilter',
      removeStoreFilter: 'removeStoreFilter',
      getFilteredCategories: 'getFilteredCategories',
      getTreeFilteredCategories: 'getTreeFilteredCategories',
      getFilteredStores: 'getFilteredStores',
      setStoreFilter: 'setStoreFilter',
      setFilterStoreByID: 'setFilterStoreByID',
      setFilterCategoryByID: 'setFilterCategoryByID',
      setFilterKeyword: 'setFilterKeyword',
      getFilteredDealsAction: 'getFilteredDeals',
      getNewDealCategories: 'getNewDealCategories',
      setGlobalFilterAction: 'setGlobalFilter',
      toggleMobileFilterBar: 'toggleMobileFilterBar'

    }),
    applyFilters: function () {
      this.setGlobalFilterAction({ storesFilter: this.selectedStores, categoriesFilter: this.selectedCategories, keywordFilter: this.keyword })
    },
    resetFilter: function (filterName) {
      switch (filterName) {
        case 'CATEGORIES':
          this.selectedCategories = []
          this.applyFilters()
          break
        case 'STORES':
          this.selectedStores = []
          this.applyFilters()
          break
        case 'KEYWORD':
          this.keyword = ''
          this.applyFilters()
          break
        default:
          break
      }
    },
    loadOptions: async function ({ action, parentNode, callback }) {
      // console.log(action)
      // console.log(this.called)
      if (action === LOAD_CHILDREN_OPTIONS) {
        await this.getTreeFilteredCategories({ parentID: parentNode.id }).then((data) => {
          parentNode.children = data
        })
        // console.log('LOAD_CHILDREN_OPTIONS of ' + parentNode.id)
        // console.log(this.called)
      }
      if (action === LOAD_ROOT_OPTIONS) {
        if (!this.called) {
          // await this.getFilteredCategories({})
          this.called = true
          this.treeCategories = this.filteredCategories
          // console.log(this.called)
        } else {
          // console.log(this.called)
          this.treeCategories = this.filteredCategories
        }
      }
    }
  },
  mixins: []
}
</script>

<style scoped>

</style>
