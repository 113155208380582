<template>
  <b-modal id="auth-modal" v-model="shouldShowAuth" :title="currentPageTitle" hide-footer>
      <div class="justify-content-md-center row">
        <ConfirmEmail @authPageChanged="setCurrentPage" v-if='Object.keys(user.loggedInUser).length !== 0 && user.loggedInUser.emailStatus === "UNVERIFIED"'></ConfirmEmail>
      </div>
      <div v-if='Object.keys(user.loggedInUser).length === 0 && !loading' class="justify-content-md-center row">
        <AuthMessage @authPageChanged="setCurrentPage" @user-logged-in="closeDialog" v-bind:message="message" @showMessage="showMessage" v-if='currentPage==="AUTH_MESSAGE" && Object.keys(user.loggedInUser).length === 0'></AuthMessage>
        <Login @authPageChanged="setCurrentPage" v-on:user-logged-in="closeDialog" v-if='currentPage==="LOGIN"'></Login><!-- End .col-md-6 -->
        <Register @authPageChanged="setCurrentPage" v-if='currentPage==="REGISTER"'  ></Register>
        <RecoverPassword @authPageChanged="setCurrentPage" v-if='currentPage==="RECOVER_PASSWORD"'></RecoverPassword>
        <ChangePassword v-bind:token="this.accessCode" @password-changed="passwordChanged" @authPageChanged="setCurrentPage" v-if='currentPage==="CHANGE_PASSWORD" '></ChangePassword>
        <!-- End .col-md-6 -->
        <!-- End .col-md-6 -->

        <!-- End .col-md-6 -->
      </div><!-- End .row -->
      <div v-if="loading">Loading ...</div>
  </b-modal>
</template>

<script>
// @ is an alias to /src

import { mapActions } from 'vuex'
import Register from '../../components/auth/Register'
import Login from '../../components/auth/Login'
import ConfirmEmail from '../../components/auth/ConfirmEmail'
import RecoverPassword from '../../components/auth/RecoverPassword'
import ChangePassword from '../../components/auth/ChangePassword'
export default {
  name: 'auth',
  components: {
    ChangePassword,
    Register,
    Login,
    ConfirmEmail,
    RecoverPassword

  },
  data: () => ({
    currentPage: 'LOGIN',
    loading: false

  }),
  computed: {
    user: {
      get () {
        return this.$store.state.User.user
      }
    },
    routeName: {
      get () {
        return this.$route.name
      }
    },
    accessCode: {
      get () {
        return this.$route.params.accessCode
      }
    },
    userID: {
      get () {
        return this.$route.params.userID
      }
    },
    shouldShowAuth: {
      get () {
        return this.$store.state.Global.shouldShowAuth && !(Object.keys(this.user.loggedInUser).length !== 0 && this.user.loggedInUser.emailStatus === 'VERIFIED')
      },
      set (visibility) {
        this.$store.commit('SET_AUTH_DIALOG_VISIBILITY', visibility)
      }
    },
    currentPageTitle: {
      get () {
        if (Object.keys(this.user.loggedInUser).length !== 0 && this.user.loggedInUser.emailStatus === 'UNVERIFIED') {
          return 'VERIFY EMAIL'
        } else {
          return this.currentPage.replace('_', ' ')
        }
      }
    }

  },
  mounted: function () {
    // this.setHeaderVisibility(false)
    if (this.user.loggedInUser !== {} && this.user.loggedInUser.emailStatus === 'VERIFIED') { this.$router.push({ name: 'home' }) }
    if (this.routeName === 'reset_password') { this.currentPage = 'CHANGE_PASSWORD'; this.shouldShowAuth = true }
    if (this.routeName === 'verify_email') { this.verifyEmail() }
  },
  methods: {
    showToast: function () {
      this.$toasted.show('Coming soon !!')
    },
    closeDialog: function () {
      // console.log('dialog closed')
      this.shouldShowAuth = false
    },
    passwordChanged: function () {
      this.shouldShowAuth = true
      this.$toasted.show('Password changed successfully').goAway(4000)
      this.$router.push({ name: 'home' })
    },
    verifyEmail: async function () {
      // // console.log(JSON.stringify(this.user))
      // console.log('verifying password')
      await this.$store.dispatch('verifyEmail', { userID: this.userID,
        code: this.accessCode }).then((data) => {
        this.fetchLoggedInUser()
        this.$toasted.show('Your email has been verified, enjoy your time on FlashSale !!',
          { onComplete: function () {
            // // console.log('toast completed')
          } }).goAway(4000)
        if (Object.keys(this.user.loggedInUser).length === 0) {
          this.shouldShowAuth = true
        }
        this.shouldShowAuth = false
        this.$router.push({ name: 'home' })
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        // console.log(error)
        if (error.response && error.response.status === 400) {
          this.$toasted.show('Invalid Verification Code',
            { onComplete: function () {
              // console.log('toast completed')
            } }).goAway(1500)
          if (Object.keys(this.user.loggedInUser).length === 0) {
            this.shouldShowAuth = true
            // console.log('setting up shouldShowAuth to true')
          }
          // this.$router.push({ name: 'home' })
          // console.log(this.errors)
        } else if (error.response && error.response.status === 409) {
          this.$toasted.show('Your email is already verified',
            { onComplete: function () {
              // console.log('toast completed')
            } }).goAway(4000)
          if (Object.keys(this.user.loggedInUser).length === 0) {
            this.shouldShowAuth = true
            // console.log('setting up shouldShowAuth to true')
          }
          this.$router.push({ name: 'home' })
          // console.log(this.errors)
        }
      })
    },
    setCurrentPage: function (page) {
      // console.log('set current page ' + page)
      this.currentPage = page
    },
    showMessage: function (message) {
      this.currentPage = 'AUTH_MESSAGE'
    },
    ...mapActions(['setHeaderVisibility', 'fetchLoggedInUser'])
  }

}
</script>
