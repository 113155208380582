<template>
  <main class="main">
    <b-container v-if="this.userStats.user">
      <b-row>
        <b-col class="mt-3">
          <h2>User Profile</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-avatar variant="info" :src="this.userStats.user.profilePicture === '' ? 'https://placekitten.com/300/300' : this.userStats.user.profilePicture"></b-avatar>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3  class="text-center mt-2 ">{{this.userStats.user.displayName}}</h3>
        </b-col>
      </b-row>

      <b-card no-body class="my-3">
              <b-card-header><div>Score board</div></b-card-header>
              <b-card-text>
                <div class="container m-2 py-4">
                  <b-row>
                    <b-col>
                      <b-row style="color:grey">
                        <b-col sm="12" md="2" class="pl-15">
                          <span class="mr-2"><font-awesome-icon :icon="['fas', 'award']"/></span>
                          {{this.userStats.user.badges.length>0 ? this.userStats.user.badges[0].label:' New User'}}
                        </b-col>
                        <b-col class="mr-0 px-0">
                          Joined: {{new Date(this.userStats.user.createdAt).toLocaleDateString("en-US",{year: 'numeric', month:'long'})}}
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col sm="12" md="3"><font-awesome-icon :icon="['fas', 'medal']"/>  {{this.userStats.stats.reputation}} Reputation Points</b-col>
                    <b-col sm="12" md="3"><font-awesome-icon :icon="['fas', 'tags']"/> {{this.userStats.stats.numberOfDeals}} Deals Posted</b-col>
                    <b-col sm="12" md="3"><font-awesome-icon :icon="['fas', 'comments']"/> {{this.userStats.stats.numberOfComments}} Comments Posted</b-col>
                    <b-col sm="12" md="3"><font-awesome-icon :icon="['fas', 'thumbs-up']"/> {{this.userStats.stats.numberOfSubmittedReports}} Votes Submitted</b-col>
                  </b-row>
                </div>
              </b-card-text>
            </b-card>
      <b-card no-body  class="my-3">
        <b-card-header>
          <div>Posted Deals</div>
        </b-card-header>
        <b-card-body v-if="userDeals.deals.length === 0">This user hasn't posted anything yet.</b-card-body>
        <b-card-body v-if="userDeals.deals.length > 0">
          <div :id="userDeal.id" v-for="userDeal in userDeals.deals" :key="userDeal.id"  class="my-3">
            <b-card no-body class="overflow-hidden" img-left >
              <b-row no-gutters>
                <b-col md="2" style="height: 100%;max-height:120px">
                  <b-card-img v-if="userDeal.mainImage" style="object-fit: scale-down" class="p-2 h-100 rounded-2" :src="'https://s3.amazonaws.com/images.flashsale.com/'+userDeal.mainImage.fd" alt="Image"></b-card-img>
                </b-col>
                <b-col md="10" >
                  <b-card-body >
                    <b-card-title><a :href="'/deal/'+userDeal.id">{{userDeal.title}}</a></b-card-title>
                    <b-card-text>
                      <pre>Status : {{userDeal.status}}   Expires At: {{new Date(Number(userDeal.expiresAt)).toLocaleDateString("en-US",{year: 'numeric', month:'long', day: 'numeric'})}} </pre>
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <div class="row justify-content-center" v-if="userDeals.numberOfUsersDeals > 3">
            <b-pagination  :total-rows="userDeals.numberOfUsersDeals" :per-page="3" align="fill" v-model="currentPage"></b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-container>

  </main>
</template>

<script>
// import the component
import DealCard from '@/components/deal/DealCard.vue'
import { mapMutations, mapActions } from 'vuex'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

/* const getCookie = name => {
  const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  return cookies ? cookies[1] : ''
} */

export default {
  components: {
    // eslint-disable-next-line no-undef
    // eslint-disable-next-line vue/no-unused-components
    DealCard },
  name: 'Profile',
  validations: {

  },
  data () {
    return {
      loading: false,
      navigateTo: 0,
      userStats: {},
      currentPage: 1,
      userDeals: {},
      count: 3
    }
  },
  watch: {
    currentPage () {
      // console.log(this.currentPage)
      this.getUserDeals({ userID: this.$route.params.userID, page: this.currentPage, count: this.count }).then((response) => {
        this.userDeals = response
      })
    }
  },
  mounted () {
    this.getUserStats(this.$route.params.userID).then((response) => {
      this.userStats = response
    })
    this.getUserDeals({ userID: this.$route.params.userID, page: this.currentPage, count: this.count }).then((response) => {
      this.userDeals = response
    })
  },
  computed: {
    user: {
      get () {
        return this.$store.state.User.user
      }
    }
  },
  methods: {
    ...mapMutations({

    }),
    ...mapActions({
      getUserStats: 'getUserStats',
      getUserDeals: 'getUserDeals'
    })
  },
  mixins: []
}
</script>

<style scoped>

</style>
