<template>
  <div>
    <b-carousel
      id="home-carousel"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
    >
      <div class="carousel-item" v-for="slide in this.slides"
        :key="slide.id">
        <section :class="`hero is-medium is-bold`">
                    <b-row sm="flex-column-reverse" class="mx-0 my-auto" :style="getStyle(JSON.parse(slide.payload).mainStyle)">
                      <b-col sm="12" lg="6" class="slider-left-pad" ><h4 class="mx-0" v-bind:style="getStyle(JSON.parse(slide.payload).titleStyle)">{{slide.title}}</h4>
                        <div>{{slide.body}}</div>
                        <div class="mt-3"><b-button :href="slide.url" :style="getStyle(JSON.parse(slide.payload).buttonStyle)">{{JSON.parse(slide.payload).buttonText}}</b-button></div>
                      </b-col>
                      <b-col sm="12" lg="6" class="slider-right-pad"><b-img :src="'https://s3.amazonaws.com/images.flashsale.com/'+slide.fd"></b-img></b-col>
                    </b-row>
                </section>
      </div>
    </b-carousel>
  </div>
</template>

<script>
import Api from '@/services/Api'
export default {
  name: 'Slider',
  props: {
    msg: String
  },
  mounted () {
    Api()
      .get('/sliders/sliders', { params: { tag: 'HOME_MAIN_SLIDER', start: 0, limit: 10 } })
      .then(({ data }) => {
        Api()
          .get('/sliders/slides', { params: { id: data.result[0].id, start: 0, limit: 6 } })
          .then((data) => {
            this.slides = data.data.result
          })
      })
  },
  data () {
    return {
      slide: 0,
      slides: []
    }
  },
  methods: {
    getStyle: function (params) {
      if (!params) {
        return {}
      }
      var styleArray = []
      // console.log(params)
      Object.keys(params).forEach(key => {
        // console.log(this.convertToKebab(key) + ' : ' + params[key]) // key - value
        styleArray.push(key + ' : ' + params[key])
      })
      // console.log(styleArray)
      return params
    },
    convertToKebab: function (params) {
      return params.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
