<template>
<b-card class="m-3 comment-card" no-body img-alt="Product">
      <b-card-body>
        <b-row class="">
          <b-col md="3" class="comment-user-card d-none d-md-block">
            <b-row>
              <b-col md="3" sm="0"  class="pr-0 pl-1"><b-avatar variant="info"></b-avatar></b-col>
              <b-col md="9" class="m-auto">
                <b><a :href="'/profile/'+comment.editor.id">{{comment.editor.displayName}}</a></b>
                <div>Joined {{dateConverter(comment.editor.createdAt)}}</div>
                <div class="mt-2"><font-awesome-icon :icon="['fas', 'award']"/>
                        {{comment.editor.badges.length > 0 ? comment.editor.badges[0].label :' New User'}}</div>
                <div><font-awesome-icon :icon="['fas', 'medal']"/> {{comment.editor.stats.reputation}} Reputation</div>
                <div><font-awesome-icon :icon="['fas', 'tags']"/> {{comment.editor.stats.numberOfDeals}} Posts</div>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="mx-1">
            <b-row class="mb-3 d-block d-md-none"><b-avatar class="mr-2" variant="info"></b-avatar><a :href="'/profile/'+comment.editor.id">{{comment.editor.displayName}}</a></b-row>
            <b-row class="comment-date">Posted {{ timeConverter(comment.createdAt) }}</b-row>
            <b-row>
              <b-card class="w-100 my-3 mr-2 comment-quote" v-if="comment.isReplyTo">
                <div class="mb-2"> Reply to &nbsp; <a :href="'/profile/'+comment.isReplyTo.editor.id">{{'&nbsp;'+comment.isReplyTo.editor.displayName}}</a></div>
                <div>{{comment.isReplyTo.body}}</div>
              </b-card>
            </b-row>
            <b-row>{{comment.body}}</b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <template #footer>
        <b-row>
          <b-col>
            <b-button variant="warning" @click.prevent="$emit('reportComment', comment)"  class="float-right ml-2 mt-2 w-40" size="sm" ><font-awesome-icon  :icon="['fas', 'flag']" class="mr-2"/>Report</b-button>
            <b-button variant="primary" @click.prevent="$emit('replyToComment', comment)" class="float-right mr-2 mt-2 w-40" size="sm"><font-awesome-icon :icon="['fas', 'reply']" class="mr-2"/> Reply</b-button>
            <b-button variant="danger" v-if="comment.editor.id === userID" @click.prevent="$emit('deleteComment', comment)" class="w-40 float-right  mt-2 mr-2" size="sm"><font-awesome-icon :icon="['fas', 'trash']" class="mr-2"/> Delete</b-button>
            <b-button variant="info" v-if="comment.editor.id === userID" @click.prevent="$emit('editComment', comment)" class="w-40 float-right  mt-2 mr-2" size="sm"><font-awesome-icon :icon="['fas', 'edit']" class="mr-2"/> Edit</b-button>
          </b-col>
        </b-row>
      </template>
  </b-card>
</template>

<script>

export default {
  components: { },
  name: 'DealComment',
  props: ['comment', 'userID'],
  data () {
    return {}
  },
  mounted () {
  },
  computed: {

  },
  methods: {
    readableCreationDate (t) {
      var cd = 24 * 60 * 60 * 1000
      var ch = 60 * 60 * 1000
      var d = Math.floor(t / cd)
      var h = Math.floor((t - d * cd) / ch)
      var m = Math.round((t - d * cd - h * ch) / 60000)
      var pad = function (n) { return n < 10 ? '0' + n : n }
      if (m === 60) {
        h++
        m = 0
      }
      if (h === 24) {
        d++
        h = 0
      }
      // var days = d === 0 ? '' : d + (pad(h) === '0' ? ' days and ' : ' days, ')
      var hours = pad(h) === '00' ? '' : pad(h) + (pad(m) === '0' ? ' hours' : ' hours and')
      var minutes = pad(m) === '0' ? '' : pad(m) + ' minutes'
      return d === 0 ? [hours, minutes].join(' ') : d + ' days'
    },
    timeConverter (t) {
      var a = new Date(t)
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      var isToday = (t) => new Date().setHours(0, 0, 0, 0) === new Date(t).setHours(0, 0, 0, 0)
      var pad = function (n) { return n < 10 ? '0' + n : n }
      var year = a.getFullYear()
      var month = months[a.getMonth()]
      var date = a.getDate()
      var h = a.getHours()
      var m = a.getMinutes()
      var hours = pad(h) === '00' ? '' : pad(h)
      var minutes = pad(m) === '0' ? '' : pad(m)
      var ampm = hours >= 12 ? ' PM' : ' AM'
      hours = hours % 12
      var time = (isToday(t) ? 'Today at' : (date + ' ' + month + ' ' + year)) + ' ' + hours + ':' + minutes + ampm
      return time
    },
    dateConverter (t) {
      var a = new Date(t)
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      var isToday = (t) => new Date().setHours(0, 0, 0, 0) === new Date(t).setHours(0, 0, 0, 0)
      var year = a.getFullYear()
      var month = months[a.getMonth()]
      var date = a.getDate()
      var time = (isToday(t) ? 'Today' : (date + ' ' + month + ' ' + year))
      return time
    }
  },
  mixins: []
}
</script>

<style scoped>

</style>
