<template>
  <header class="header mb-0">
    <div>
  <b-navbar toggleable="lg" type="dark">
    <b-navbar-brand class="logo" href="/" src="/assets/images/g24-7.png">
      <img data-v-ed558de2="" src="/assets/images/g24-7.png" alt="Flashsale Logo">
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-input-group>
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search" v-model="searchWord"></b-form-input>
            <b-input-group-append>
              <!-- <b-button size="sm" :href=" 'deals/_/_/'+searchWord !==''?searchWord:'_'" > -->
              <b-button size="sm" :href=" '/deals/_/_/'+(searchWord !==''?searchWord:'_')" >
                <font-awesome-icon :icon="['fas', 'search']"/>
              </b-button>
            </b-input-group-append>
            <b-input-group-append class="ml-2">
              <b-button size="sm"  @click.prevent="createDeal">Post a Deal</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-nav-form>
        <b-nav-item class="user-actions-dropdown" v-if="!this.isUserAuthenticated" @click.prevent="shouldShowAuth = true" v-b-modal.auth-modal>Login</b-nav-item>
        <div v-if="this.isUserAuthenticated" class="header-dropdown">
          <b-nav-item-dropdown right class="user-actions-dropdown">
          <!-- Using 'button-content' slot -->
            <template #button-content>
              Hello <b>{{user.displayName}}</b>
            </template>
            <b-dropdown-item :href="'/profile/'+this.user.id">Profile</b-dropdown-item>
            <!-- <b-dropdown-item href="#">Sales' Alerts</b-dropdown-item> -->
            <b-dropdown-item v-if="this.isAdmin" href="/admin">Admin Panel</b-dropdown-item>
            <b-dropdown-item href="#"  @click.prevent="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </div>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
    <b-sidebar id="sidebar-1" right title="Menu" shadow>
      <div class="px-3 py-2">
        <b-list-group>
          <b-list-group-item href="#">Login</b-list-group-item>
          <b-list-group-item v-bind:href="'/profile/'+this.user.id">Profile</b-list-group-item>
          <b-list-group-item href="#">Post a Deal</b-list-group-item>
          <b-list-group-item href="#">Admin Panel</b-list-group-item>
          <b-list-group-item href="#">Logout</b-list-group-item>
        </b-list-group>
      </div>
    </b-sidebar>
    <CategoriesHorizontalMenu></CategoriesHorizontalMenu>
    <!-- End .header-bottom -->

  </header>
</template>

<script>
import CategoriesHorizontalMenu from '@/components/header/CategoriesHorizontalMenu.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Header',
  components: {
    CategoriesHorizontalMenu
  },
  data () {
    return {
      searchWord: ''
    }
  },
  computed: {
    user: {
      get () {
        return this.$store.state.User.user.loggedInUser
      }
    },
    isUserAuthenticated: {
      get () {
        return this.$store.state.isUserAuthenticated
      }
    },
    shouldShowAuth: {
      get () {
        return this.$store.state.Global.shouldShowAuth
      },
      set (visibility) {
        this.$store.commit('SET_AUTH_DIALOG_VISIBILITY', visibility)
      }
    },
    isAdmin: function () {
      if (this.user.role === 'SUPER_ADMIN' || this.user.role === 'ADMIN') {
        return true
      } else {
        return false
      }
    }
  },
  props: {
    msg: String
  },
  methods: {
    showModal: function () {
      // console.log(this.$info)
    },
    logout: async function () {
      await this.$store.dispatch('signOut').then((data) => {
        // console.log(data)
      })
    },
    mounted: function () {
      this.buildActions()
    },
    buildActions: function () {
      let actions = []

      if (!this.isUserAuthenticated) {
        actions.push({
          name: this.t('app.mixin.register'),
          method: () => {
            this.$router.push({ name: 'Register' })
          }
        })

        actions.push({
          name: this.t('app.mixin.login'),
          method: () => {
            this.$router.push({ name: 'Login' })
          }
        })
      } else {
        actions.push({
          name: this.t('app.mixin.shop'),
          method: () => {
            this.$router.push({ name: 'Shop' })
          }
        })

        actions.push({
          name: this.t('app.mixin.basket'),
          method: () => {
            this.$router.push({ name: 'Basket' })
          }
        })

        actions.push({
          name: this.t('app.mixin.products'),
          method: () => {
            this.$router.push({ name: 'Products' })
          }
        })

        actions.push({
          name: 'Logout',
          method: () => {
            this.deleteCookie('user')
            this.isUserAuthenticated = false

            this.$nextTick(() => this.buildActions())

            this.$toasted.show(this.t('app.mobile.logout'),
              { onComplete: function () {
                // console.log('toast completed')
              } }).goAway(1500)
            this.$router.push({ name: 'Home' })
          }
        })
      }

      actions.push({
        name: 'Change language',
        method: () => {
          const locale = this.$store.state.locale

          if (locale === 'en') this.setLocale('de')
          if (locale === 'de') this.setLocale('en')

          this.$toasted.show(this.t('app.mixin.language.changed'),
            { onComplete: function () {
              // console.log('toast completed')
            } }).goAway(1500)

          this.$nextTick(() => this.setActions())
        }
      })

      this.$set(this, 'actions', actions)
    },
    showNewDealDialog () {
      this.$modal.show('hello-world')
    },
    hideNewDealDialog () {
      this.$modal.hide('hello-world')
    },
    createDeal () {
      if (Object.keys(this.user).length === 0) {
        this.shouldShowAuth = true
      } else {
        this.$router.push({ name: 'createDeal' })
      }
    },
    ...mapActions(['setHeaderVisibility', 'signOut'])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
