import { mapMutations } from 'vuex'
import { required, minLength, email } from 'vuelidate/lib/validators'
export default {
  props: [
    'token'
  ],
  data: function () {
    return {
      email: '',
      password: '',
      passwordVerification: '',
      passwordChanged: false
    }
  },
  computed: {
    user: {
      get () {
        return this.$store.state.User.user
      }
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(5)
    },
    passwordVerification: {
      required,
      minLength: minLength(5)
    }
  },
  methods: {
    async changePassword () {
      await this.$store.dispatch('changePassword', { emailAddress: this.email, newPassword: this.password, verificationToken: this.token }).then((data) => {
        this.isLoading = false
        // window.localStorage.setItem('x-token', data.authToken)
        // this.setCookie('user', data.id, 3600 * 24 * 7)
        this.passwordChanged = true
        this.$emit('password-changed')
      }).catch((error) => {
        this.isLoading = false
        if (error.response.status === 409) {
          this.errors.push({ message: 'This email has already been used', id: error.response.status })
        }
      })

      // this.$router.push({ name: 'Products' })
    },

    setCookie (name, value, seconds) {
      const expires = seconds ? '; expires=' + new Date(new Date().getTime() + seconds * 1000).toGMTString() : ''
      document.cookie = name + '=' + encodeURIComponent(value) + expires + '; path=/'
    },

    ...mapMutations({
      setIsUserAuthenticated: 'SET_IS_USER_AUTHENTICATED',
      setLoggedInUser: 'SET_LOGGED_IN_USER'
    })
  }
}
