
<template>
<b-card class="mb-3 deal-card" no-body img-alt="Product" img-top>
      <b-card-img-lazy height="200" :src="'https://s3.amazonaws.com/images.flashsale.com/'+deal.thumbnail">
      </b-card-img-lazy>
      <b-card-body>
        <a class="bold" v-bind:href="'/deal/'+deal.id">{{deal.title}}</a>
      </b-card-body>
      <template #footer>
        <b-row class="my-2">
          <b-col>
            <span class="old-price">${{deal.oldPrice}}</span>
            <span class="product-price">${{deal.newPrice}}</span>
          </b-col>
          <b-col>
            <span class="num-likes float-right"><font-awesome-icon class="icon" :icon="['fas', 'thumbs-up']"/> {{deal.numOfLikes-deal.numOfDislikes}}</span>

          </b-col>
        </b-row>
        <b-row>
          <b-col class="w-100">
            <b-button class=" w-100 btn-icon btn-show-deal" v-bind:href="'/deal/'+deal.id">SHOW DETAILS</b-button>
          </b-col>
        </b-row>
        <small class="text-muted">Last updated {{ readableCreationDate(Date.now() - deal.createdAt) }} ago</small>
      </template>
  </b-card>
</template>

<script>
// import the component
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
/*
const getCookie = name => {
  const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  return cookies ? cookies[1] : ''
} */

export default {
  components: { },
  name: 'DealCard',
  props: ['deal'],
  data () {
    return {}
  },
  mounted () {
  },
  computed: {

    ratingToolTip: function () {
      if (this.deal.rating === 0) { return 'not liked yet' } else { return 'Deal rating is ' + this.deal.rating + '%' }
    }

  },
  methods: {
    readableCreationDate (t) {
      var cd = 24 * 60 * 60 * 1000
      var ch = 60 * 60 * 1000
      var d = Math.floor(t / cd)
      var h = Math.floor((t - d * cd) / ch)
      var m = Math.round((t - d * cd - h * ch) / 60000)
      var pad = function (n) { return n < 10 ? '0' + n : n }
      if (m === 60) {
        h++
        m = 0
      }
      if (h === 24) {
        d++
        h = 0
      }
      // var days = d === 0 ? '' : d + (pad(h) === '0' ? ' days and ' : ' days, ')
      var hours = pad(h) === '00' ? '' : pad(h) + (pad(m) === '0' ? ' hours' : ' hours and')
      var minutes = pad(m) === '0' ? '' : pad(m) + ' minutes'
      // console.log(d)
      return d === 0 ? [hours, minutes].join(' ') : d + ' days'
    }
  },
  mixins: []
}
</script>

<style scoped>

</style>
