<template>
  <div>
    <b-card-group deck  v-for="(featuredDealsRow, index) in featuredDeals" :key="'row-'+index" >
      <deal-card :id="featuredDeal.id" :deal="featuredDeal" v-for="featuredDeal in featuredDealsRow" :key="featuredDeal.id"></deal-card>
    </b-card-group>
    <div class="row w-100 justify-content-center" v-if="numberOfFeaturedDeals">
      <b-pagination  :total-rows="numberOfFeaturedDeals" :per-page="8" align="fill" v-model="currentPage"></b-pagination>
    </div>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
import DealCard from '../deal/DealCard'
export default {
  components: { DealCard },
  created () {
    this.getFeaturedDeals({ page: this.currentPage, count: 8 })
  },
  name: 'FeaturedDeals',
  data: () => ({
    currentPage: 1
  }),
  watch: {
    currentPage () {
      this.getFeaturedDeals({ page: this.currentPage, count: 8 })
    }
  },
  computed: {
    numberOfFeaturedDeals: {
      get () {
        return this.$store.state.Deals.numberOfFeaturedDeals
      }
    },
    featuredDeals: {
      get () {
        if (this.$store.state.Deals.featuredDeals.length > 0) {
          return this.$store.state.Deals.featuredDeals.reduce((c, n, i) => {
            if (i % 4 === 0) c.push([])
            c[c.length - 1].push(n)
            return c
          }, [])
        } else {
          return []
        }
      }
    }
  },
  methods: {

    ...mapActions(['getFeaturedDeals'])
  }
}
</script>

<style scoped>

</style>
