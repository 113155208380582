<template>
  <div class="header-bottom mb-5">

    <b-nav align="center">
        <b-nav-item :id="featuredCategory.id" v-for="featuredCategory in featuredCategories" :key="featuredCategory.id" v-bind:href=" '/deals/' + featuredCategory.id +'/_/_'">
          {{featuredCategory.title}}
        </b-nav-item>
    </b-nav>

  </div>

</template>

<script>
import { mapActions } from 'vuex'
export default {
  created () {
    this.getFeaturedCategories()
  },
  name: 'CategoriesHorizontalMenu',
  data: () => ({}),
  computed: {
    featuredCategories: {
      get () {
        return this.$store.state.Categories.featuredCategories
      }
    }
  },
  methods: {

    ...mapActions(['getFeaturedCategories'])
  }
}
</script>

<style scoped>

</style>
