import Api from '@/services/Api'

export default {
  setStoreFilter (context, data) {
    return new Promise((resolve, reject) => {
      context.commit('SET_STORE_FILTER', data)
    })
  },
  removeStoreFilter (context, data) {
    return new Promise((resolve, reject) => {
      context.commit('REMOVE_STORE_FILTER', data)
    })
  },
  removeCategoryFilter (context, data) {
    return new Promise((resolve, reject) => {
      context.commit('REMOVE_CATEGORY_FILTER', data)
    })
  },
  removeKeywordFilter (context, data) {
    return new Promise((resolve, reject) => {
      context.commit('REMOVE_KEYWORD_FILTER', data)
    })
  },
  setFilterCategoryByID (context, data) {
    return new Promise((resolve, reject) => {
      Api()
        .get('/categories/get_category', { params: { id: data } })
        .then(({ data }) => {
          // console.log('setFilteredCategoryByID')
          data = [data].map((e) => { return { id: e.id, label: e.title, ...e.numOfChildren !== 0 && { children: null } } })
          let object = data[0]
          object.preventWatchers = true
          context.commit('SET_CATEGORY_FILTER', object)
          resolve(data)
        })
        .catch(error => reject(error))
    })
  },
  setFilterStoreByID (context, data) {
    return new Promise((resolve, reject) => {
      Api()
        .get('/stores/filtered_stores', { params: { id: data } })
        .then(({ data }) => {
          // console.log('setFilterStoreByID')
          // data = [data].map((e) => { return { id: e.id, label: e.title, ...e.numOfChildren !== 0 && { children: null } } })
          let object = data.rows
          object.preventWatchers = true
          context.commit('SET_STORE_FILTER', object)
          resolve(data)
        })
        .catch(error => reject(error))
    })
  },
  setGlobalFilter (context, data) {
    context.commit('SET_GLOBAL_FILTER', data)
  }

}
