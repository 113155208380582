export default {
  SET_USER (state, user) {
    state.user = user
  },
  SET_LOGGED_IN_USER (state, user) {
    // console.log(user)
    state.user.loggedInUser = user
  },

  SET_EMAIL (state, email) {
    state.user.email = email
  },

  SET_USER_PASSWORD (state, password) {
    state.user.password = password
  },
  SET_USER_DISPLAY_NAME (state, displayName) {
    state.user.displayName = displayName
  },
  RESET_USER (state) {
    state.user.name = ''
    state.user.password = ''
  },
  SET_EMAIL_VERIFICATION_CODE (state, code) {
    state.user.emailVerificationCode = code
  }
}
