<template>
  <div class="col-md-12">
    <div v-if="passwordChanged">Your password has been changed successfully
      <br/>
      <b-row class="mx-1 my-4 float-right">
          <b-button
            variant="primary"
            class="float-right" @click.prevent="$emit('authPageChanged','LOGIN')">Go To Login</b-button>
        </b-row>
    </div>
    <form action="#" v-if="!passwordChanged" class="mb-1">
      <b-form-group label-for="reset-pass-email" label="Email address" :class="{ 'form-group--error': $v.email.$error }">
        <b-form-input type="email" placeholder="Enter email" class="form-input form-wide mb-2" v-model.trim="$v.email.$model" id="reset-pass-email" ></b-form-input>
        <b-form-invalid-feedback class="error" :state="($v.email.email || (!this.$v.email.$dirty))">invalid email address</b-form-invalid-feedback>
        <b-form-invalid-feedback :state="(!this.$v.email.$dirty)||(this.$v.email.required)">
          Email field is required
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Password" label-for="new-password" :class="{ 'form-group--error': $v.password.$error }">
        <!--<tree-view :data="$v.email" :options="{rootObjectKey: '$v.email', maxDepth: 2}"></tree-view>-->
        <b-form-input type="password" class="form-input form-wide mb-0" v-model.trim="$v.password.$model" id="new-password" ></b-form-input>
        <b-form-invalid-feedback :state="($v.password.required || !$v.password.$dirty)">
          Password is required
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="($v.password.minLength || !$v.password.$dirty)">
          Password should be at least {{$v.password.$params.minLength.min}} characters long.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Repeat Password" label-for="repeat-password" class="form-group" :class="{ 'form-group--error': $v.passwordVerification.$error }">
        <b-form-input type="password" v-model.trim="$v.passwordVerification.$model" id="repeat-password" ></b-form-input>
        <b-form-invalid-feedback :state="($v.passwordVerification.required || !$v.passwordVerification.$dirty)">
          Please confirm your password
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="(passwordVerification === password || !$v.passwordVerification.required || !$v.passwordVerification.$dirty)">
          Passwords don't match
        </b-form-invalid-feedback>
      </b-form-group>
      <footer class="modal-footer">
        <b-row class="w-100">
          <b-col class="float-left my-auto px-0">Changed your mind ? <a @click.prevent="$emit('authPageChanged','LOGIN')" href="#" class=""> Login</a></b-col>
          <b-button
            variant="primary"
            class="float-right" @click.prevent="changePassword">Change Password</b-button>
        </b-row>
      </footer>

    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ChangePasswordMixin from './ChangePassword.mixin'

export default {
  name: 'ChangePassword',
  mixins: [validationMixin, ChangePasswordMixin]
}
</script>

<style scoped>

</style>
