<template>
  <div id="app">
    <div class="page-wrapper">
      <div v-if="this.shouldShowHeader && this.user.loggedInUser.emailStatus !== undefined && this.user.loggedInUser.emailStatus !=='VERIFIED'"
           class="alert-danger top-alert">
        Please verify your email address :  <a href="#" @click.prevent="shouldShowAuth=true"
      >VERIFY</a></div>
      <Header v-if="this.shouldShowHeader"></Header>
      <router-view></router-view>
      <Auth v-if="shouldShowAuth"></Auth>
      <Footer></Footer>
    </div>

  </div>
</template>

<script>
import Header from '@/components/header/Header.vue'
import Footer from '@/components/footer/Footer.vue'
import Auth from './views/auth/Auth'
import { mapActions } from 'vuex'

export default {
  name: 'app',
  components: {
    Auth,
    Header,
    Footer
  },
  mounted () {
    this.fetchLoggedInUser()
    console.log()
    if (this.routeName === 'reset_password') { this.shouldShowAuth = true }
    if (this.routeName === 'verify_email') { this.shouldShowAuth = true }
  },
  computed: {
    shouldShowHeader: {
      get () {
        return this.$store.state.Global.shouldShowHeader
      }
    },
    routeName: {
      get () {
        return this.$route.name
      }
    },
    shouldShowAuth: {
      get () {
        return this.$store.state.Global.shouldShowAuth
      },
      set (visibility) {
        this.$store.commit('SET_AUTH_DIALOG_VISIBILITY', visibility)
      }
    },
    user: {
      get () {
        return this.$store.state.User.user
      }
    },
    isUserAuthenticated: {
      get () {
        return this.$store.state.isUserAuthenticated
      }
    }
  },
  methods: {
    ...mapActions({
      setAuthDialogVisibility: 'removeStoreFilter',
      signOut: 'signOut'
    }),
    fetchLoggedInUser: async function () {
      var vm = this
      if (this.isUserAuthenticated) {
        await this.$store.dispatch('fetchLoggedInUser').then((data) => {
          // console.log('fetchLoggedInUser called')
          if (Object.keys(this.user.loggedInUser).length === 0) {
            this.shouldShowAuth = true
          }
        }).catch(async function (error) {
          Promise.reject(error)
          await vm.$store.dispatch('signOut').then((data) => {
            // console.log(data)
          })
          // console.log(error)
        })
      }
    }
  }

}
</script>

<style>

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
