import { required, minLength, email } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'
export default {
  computed: {
  },
  data: function () {
    return {
      user: {},
      displayName: null,
      password: null,
      email: null,
      repeatPassword: '',
      errors: [],
      isLoading: false

    }
  },
  validations: {
    email: {
      required,
      email
    },
    displayName: {
      required
    },
    password: {
      required,
      minLength: minLength(5)
    },
    repeatPassword: {
      required
    }
  },

  methods: {
    async signUp () {
      this.errors.length = 0
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const token = await this.$recaptcha('register')
        this.isLoading = true
        await this.$store.dispatch('signUp', { displayName: this.displayName, password: this.password, email: this.email, captchaToken: token }).then((data) => {
          this.isLoading = false
          window.localStorage.setItem('x-token', data.authToken)
          this.setCookie('user', data.id, 3600 * 24 * 7)
          this.$emit('user-logged-in')
        }).catch((error) => {
          this.isLoading = false
          // console.log(error.response)
          if (error.response !== undefined && error.response.status === 409) {
            if (error.response.data === 'EMAIL_EXISTS') {
              this.errors.push({ message: 'This email has already been used', id: error.response.status })
            } else if (error.response.data === 'DISPLAY_NAME_EXISTS') {
              this.errors.push({ message: 'This Display Name has already been used', id: error.response.status })
            }

            // console.log(this.errors)
          } else {
            this.errors.push({ message: 'An error has occurred', id: 222 })
          }
        })
      }
    },
    setCookie (name, value, seconds) {
      const expires = seconds ? '; expires=' + new Date(new Date().getTime() + seconds * 1000).toGMTString() : ''
      document.cookie = name + '=' + encodeURIComponent(value) + expires + '; path=/'
    },
    ...mapMutations({
      setIsUserAuthenticated: 'SET_IS_USER_AUTHENTICATED'
    })
  }
}
