export default {
  editorsChoiceDeals: {},
  featuredDeals: {},
  filteredDeals: {},
  widgetFeaturedDeals: {},
  numberOfFeaturedDeals: 0,
  numberOfEditorsChoiceDeals: 0,
  editorsChoiceCollectionID: '63a36e97-5d13-48b7-9c52-8672c4da5047',
  featuredDealCollectionID: '9a74646d-beed-4667-b239-fc914bf1caff'
}
