import Api from '@/services/Api'

export default {
  getFeaturedCategories (context) {
    return new Promise((resolve, reject) => {
      Api()
        .get('/categories/homepage_categories', { params: { limit: 7 } })
        .then(({ data }) => {
          // console.log(data)
          context.commit('SET_FEATURED_CATEGORIES', data)
          resolve()
        })
        .catch(error => reject(error))
    })
  },
  getAllCategories (context) {
    return new Promise((resolve, reject) => {
      Api()
        .get('/categories/all_categories', { params: { limit: 14 } })
        .then(({ data }) => {
          // console.log(data)
          context.commit('SET_ALL_CATEGORIES', data)
          resolve()
        })
        .catch(error => reject(error))
    })
  },
  getFilteredCategories (context, data) {
    return new Promise((resolve, reject) => {
      // console.log(data)
      Api()
        .get('/categories/filtered_categories', { params: {
          ...data.keyword !== undefined && { keyword: data.keyword },
          ...(data.stores !== undefined && data.stores !== {}) && { stores: data.stores },
          parentID: !data.parentID ? 'ROOT_ELEMENT' : data.parentID } })
        .then(({ data }) => {
          // console.log(data)
          data = data.rows.map((e) => { return { id: e.id, label: e.title, ...(e.rgt - e.lft) > 1 && { children: null }, numberOfDeals: e.num_of_deals } })
          if (data.parentID) this.$store.dispatch('setFilterCategoryByID', data.parentID)
          context.commit('SET_FILTERED_CATEGORIES', data)
          resolve(data)
        })
        .catch(error => reject(error))
    })
  },
  getTreeFilteredCategories (context, data) {
    return new Promise((resolve, reject) => {
      // console.log(data)
      Api()
        .get('/categories/filtered_categories', { params: {
          parentID: !data.parentID ? 'ROOT_ELEMENT' : data.parentID } })
        .then(({ data }) => {
          // console.log(data)
          data = data.rows.map((e) => { return { id: e.id, label: e.title, ...(e.rgt - e.lft) > 1 && { children: null }, numberOfDeals: e.num_of_deals } })
          resolve(data)
        })
        .catch(error => reject(error))
    })
  },
  getNewDealCategories (context, data) {
    return new Promise((resolve, reject) => {
      Api()
        .get('/categories/all_categories', { params: {
          parentID: !data.parentID ? 'ROOT_ELEMENT' : data.parentID } })
        .then(({ data }) => {
          data = data.map((e) => { return { id: e.id, label: e.title, ...(e.rgt - e.lft) > 1 && { children: null } } })

          context.commit('SET_NEW_DEAL_CATEGORIES', data)
          resolve(data)
        })
        .catch(error => reject(error))
    })
  }

}
