export default () => {
  var imageTools = {
    thumbnailify: function (base64Image, targetSize) {
      return new Promise((resolve, reject) => {
        var img = new Image()
        // console.log(base64Image)
        img.onload = function () {
          var width = img.width
          var height = img.height
          var canvas = document.createElement('canvas')
          var ctx = canvas.getContext('2d')

          canvas.height = targetSize
          canvas.width = img.width / img.height * targetSize
          ctx.drawImage(
            img,
            0,
            0,
            width,
            height,
            0, 0,
            img.width / img.height * targetSize, targetSize
          )

          resolve(canvas.toDataURL())
        }

        img.src = base64Image
      })
    }
  }
  return imageTools
}
