
<template>
    <div class="comments-list w-100">
        <b-row class="m-0">
            <b-col class="mr-1"><b-button class="float-right" @click="newCommentDialog"><font-awesome-icon :icon="['fas', 'comments']"/> New comment</b-button></b-col>
        </b-row>
        <Comment @replyToComment="replyToCommentDialog" @editComment="editCommentDialog" @reportComment="reportComment" @deleteComment="deleteComment"
        v-for= "comment in commentsData.comments" :key="comment.id" :comment="comment" :userID="user.loggedInUser.id"></Comment>
        <div class="comments-list-footer">
            <div class="row justify-content-center" v-if="commentsData.numberOfComments > currentPage">
                <b-pagination  :total-rows="commentsData.numberOfComments" :per-page="commentsPerPage" align="fill" v-model="currentPage"></b-pagination>
            </div>
        </div>
        <b-modal @hide="selectedComment={}"
            @ok="submitComment" id="comment-modal"
            v-model="shouldShowNewCommentDialog"
            :title="selectedComment.id? 'Reply to '+selectedComment.editor.displayName : 'New Comment'">
            <b-card class="w-100 my-3 mr-2 comment-quote" style="background-color: #e7e7e7;"  v-if="selectedComment.id">
                <div class="mb-2"><b>Original Comment:</b></div>
                <div>{{selectedComment.body}}</div>
            </b-card>
            <b-form-textarea
            id="textarea"
            placeholder="Enter something..."
            v-model="newCommentBody"
            rows="3"
            max-rows="6"
            ></b-form-textarea>
        </b-modal>
    </div>
</template>

<script>
import Api from '@/services/Api'
import Comment from '../comment/Comment.vue'
export default {
  components: { Comment },
  name: 'DealCommentsList',
  props: ['dealID'],
  data () {
    return {
      commentsData: { numberOfComments: 0, comments: [] },
      numberOfComments: 0,
      currentPage: 1,
      commentsPerPage: 8,
      selectedComment: {},
      editedComment: {},
      shouldShowNewCommentDialog: false,
      newCommentBody: ''
    }
  },
  mounted () {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.User.user
      }
    },
    shouldShowAuth: {
      get () {
        return this.$store.state.Global.shouldShowAuth
      },
      set (visibility) {
        this.$store.commit('SET_AUTH_DIALOG_VISIBILITY', visibility)
      }
    }
  },
  watch: {
    dealID: function (newValue, oldValue) {
      if (newValue) {
        this.getComments()
      }
    },
    currentPage: function () {
      this.getComments()
    }
  },
  methods: {
    getComments: function () {
      Api()
        .get('/comments/deal_comments', { params: { deal: this.dealID, start: (this.currentPage - 1) * this.commentsPerPage, limit: this.commentsPerPage } })
        .then((data) => {
          this.commentsData = data.data
        })
    },
    replyToCommentDialog: function (parentComment) {
      if (Object.keys(this.user.loggedInUser).length === 0) {
        this.shouldShowAuth = true
        return
      }
      this.selectedComment = parentComment
      this.shouldShowNewCommentDialog = true
    },
    editCommentDialog: function (parentComment) {
      if (Object.keys(this.user.loggedInUser).length === 0) {
        this.shouldShowAuth = true
        return
      }
      this.selectedComment = parentComment.isReplyTo ? parentComment.isReplyTo : {}
      this.editedComment = parentComment
      this.newCommentBody = parentComment.body
      this.shouldShowNewCommentDialog = true
    },
    newCommentDialog: function () {
      if (Object.keys(this.user.loggedInUser).length === 0) {
        this.shouldShowAuth = true
        return
      }
      this.selectedComment = {}
      this.shouldShowNewCommentDialog = true
    },
    submitComment: function () {
      // console.log(this.newCommentBody.length)
      if (this.newCommentBody.length > 5 && Object.keys(this.user.loggedInUser).length !== 0) {
        Api()
          .get('/comments/create_or_edit_comment', { params: {
            ...this.editedComment.id && { id: this.editedComment.id },
            title: 'N/A',
            deal: this.dealID,
            editor: this.user.loggedInUser.id,
            body: this.newCommentBody,
            ...this.selectedComment && { isReplyTo: this.selectedComment.id } } })
          .then((data) => {
            this.hideNewCommentDialog()
            if (!this.editedComment.id) {
              // new comment
              this.currentPage = Math.floor(this.commentsData.numberOfComments / this.commentsPerPage) + 1
            }

            this.editedComment = {}
            this.selectedComment = {}
            this.newCommentBody = ''

            this.getComments()
          })
      }
    },
    editComment: function () {
      // console.log(this.newCommentBody.length)
      if (this.newCommentBody.length > 5 && Object.keys(this.user.loggedInUser).length !== 0) {
        Api()
          .get('/comments/create_or_edit_comment', { params: { ...this.editedComment.id && { id: this.editedComment.id }, title: 'N/A', deal: this.dealID, editor: this.user.loggedInUser.id, body: this.newCommentBody, ...this.selectedComment && { isReplyTo: this.selectedComment.id } } })
          .then((data) => {
            this.hideNewCommentDialog()
            this.editedComment = {}
            this.selectedComment = {}
            this.newCommentBody = ''
            this.currentPage = Math.floor(this.commentsData.numberOfComments / this.commentsPerPage) + 1
            this.getComments()
          })
      }
    },
    reportComment: function (comment) {
      Api().post('comments/report_comment', { reportType: 'INAPPROPRIATE', commentID: comment.id, userID: this.user.loggedInUser.id }).then(response => {
        if (response.status === 200) {
          this.$toasted.show('Thank you for reporting this comment, we will take action soon.',
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        }
      })
        .catch(error => {
          this.$toasted.show(error.message,
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        })
    },
    deleteComment: function (comment) {
      Api().post('comments/delete_comment', { id: comment.id, submitter: this.user.loggedInUser.id }).then(response => {
        if (response.status === 200) {
          this.getComments()
          this.$toasted.show('Comment Deleted',
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        }
      })
        .catch(error => {
          this.$toasted.show(error.message,
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        })
    },
    hideNewCommentDialog: function () {
      this.shouldShowNewCommentDialog = false
      this.selectedComment = {}
      this.newCommentBody = ''
      this.editedComment = {}
    }
  },
  mixins: []
}
</script>

<style scoped>

</style>
