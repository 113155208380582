
<template>
  <div class="container">
    <div class="justify-content-md-center row">
      <div class="col-md-6">
        <form id="create-deal-form">

          <div><h2 class="title mb-2">Post a Deal</h2></div>
          <b-form-group label-for="deal-title" label="Deal Title *" :class="{ 'form-group--error': $v.newDeal.title.$error }">
            <b-form-input type="text" placeholder="Enter title" class="form-input form-wide mb-2" v-model.trim="$v.newDeal.title.$model" id="deal-title" ></b-form-input>
            <b-form-invalid-feedback :state="(!this.$v.newDeal.title.$dirty)||(this.$v.newDeal.title.required)">
              Please enter a title for this deal.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="deal-url" label="Deal URL *" :class="{ 'form-group--error': $v.newDeal.url.$error }">
            <b-form-input type="url" placeholder="http://" class="form-input form-wide mb-2" v-model.trim="$v.newDeal.url.$model" id="deal-url" ></b-form-input>
            <b-form-invalid-feedback :state="(!this.$v.newDeal.url.$dirty)||(this.$v.newDeal.url.required)">
              Please enter a URL for this deal.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="deal-price" label="Deal Price *" :class="{ 'form-group--error': $v.newDeal.newPrice.$error }">
            <b-form-input type="number" placeholder="Price" class="form-input form-wide mb-2" v-model.trim="$v.newDeal.newPrice.$model" id="deal-price" ></b-form-input>
            <b-form-invalid-feedback :state="(!this.$v.newDeal.newPrice.$dirty)||(this.$v.newDeal.newPrice.required)">
              Please enter the discounted (new) price for this deal.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="deal-old-price" label="Old Price *" :class="{ 'form-group--error': $v.newDeal.oldPrice.$error }">
            <b-form-input type="number" placeholder="Old Price" class="form-input form-wide mb-2" v-model.trim="$v.newDeal.oldPrice.$model" id="deal-old-price" ></b-form-input>
            <b-form-invalid-feedback :state="(!this.$v.newDeal.oldPrice.$dirty)||(this.$v.newDeal.oldPrice.required)">
              Please enter the old (full) price for this deal.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="expiration-date" label="Expiration Date">
            <datetime type="datetime" :phrases="{ok: 'Continue', cancel: 'Exit'}" auto placeholder="Enter a date" class="form-input form-wide mb-2" id="expiration-date" v-model="newDeal.expirationDate" use12-hour></datetime>
          </b-form-group>
          <b-form-group label-for="deal-category" label="Category *">
            <treeselect
              placeholder="Search Category"
              :load-options="loadOptions"
              :auto-load-root-options="false"
              :options="treeCategories"
              id="deal-category" v-model="$v.newDeal.categories.$model"  :multiple="true"  />
            <b-form-invalid-feedback :state="(!this.$v.newDeal.categories.$dirty)||(this.$v.newDeal.categories.required)">
              Please select one or multiple categories for this deal.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="deal-store" label="Store(s) *" >
            <!-- <treeselect id="deal-store" v-model="$v.newDeal.stores.$model" placeholder="Type the first two letters" :multiple="true" :async="true" :load-options="loadStores"  :options="stores" /> -->
            <treeselect ref="storesTreeSelect" id="deal-store" v-model="$v.newDeal.stores.$model" placeholder="Type the first two letters" :multiple="true" :async="true" :load-options="loadStores"  :options="stores"/>
            <b-form-invalid-feedback :state="(!this.$v.newDeal.stores.$dirty)||(this.$v.newDeal.stores.required)">
              Please pick one or multiple stores for this deal.
            </b-form-invalid-feedback>
          </b-form-group >
          <b-form-group label-for="deal-description" label="Description *" >
            <!--<tree-view :data="$v.email" :options="{rootObjectKey: '$v.email', maxDepth: 2}"></tree-view>-->
            <ckeditor :editor="editor" :config="editorConfig" v-model.trim="$v.newDeal.description.$model" id="deal-description"></ckeditor>
            <b-form-invalid-feedback :state="(!this.$v.newDeal.description.$dirty)||(this.$v.newDeal.description.required)">
              Please write few words about this deal.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--<tree-view :data="$v.email" :options="{rootObjectKey: '$v.email', maxDepth: 2}"></tree-view>-->
          <div class="form-group">
            <label for="deal-url">Images <span class="required">*</span></label>
            <b-list-group>
              <b-card v-for="image in newDeal.images" v-bind:key="image.id" class="images-preview">
                <b-row no-gutter>
                  <b-col md="3">
                    <b-card-img-lazy left height="100" :src="'https://s3.amazonaws.com/images.flashsale.com/'+image.fd"></b-card-img-lazy>
                  </b-col>
                  <b-col class="my-auto">
                    <b-button-group class="float-right" size="md" >
                      <b-button :variant="isMainImage(image.id)?'success':'outline-primary'" :pressed="newDeal.mainImage === image.id" @click.prevent="newDeal.mainImage = image.id">{{getMainImageText(image.id)}}</b-button>
                      <b-button variant="danger" v-on:click.prevent="removeImage(image.id)"><font-awesome-icon :icon="['fas', 'trash']"/></b-button>
                    </b-button-group>
                  </b-col>
                </b-row>
              </b-card>
              <b-list-group-item v-if="uploadingImage">Uploading</b-list-group-item>
            </b-list-group>
            <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-sending="sendingEvent" v-on:vdropzone-success="fileUploaded" v-on:vdropzone-thumbnail="thumbnailEvent"></vue-dropzone>
            <div class="error" v-if="(!this.$v.newDeal.images.required) && (this.$v.newDeal.images.$dirty)">You need to upload at least one image</div>
          </div>

          <div class="form-footer">
            <button  v-on:click.prevent="submitDeal" class="btn btn-primary btn-md">Submit New Deal</button>
          </div><!-- End .form-footer -->

        </form>
      </div>

    </div>
  </div>
</template>

<script>
// import the component
import Treeselect, { LOAD_CHILDREN_OPTIONS, LOAD_ROOT_OPTIONS, ASYNC_SEARCH } from '@riophae/vue-treeselect'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import vue2Dropzone from 'vue2-dropzone'
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'
import { mapMutations, mapActions } from 'vuex'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { Datetime } from 'vue-datetime'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import imageTools from '@/services/ImageTools'
import Api from '@/services/Api'

const getCookie = name => {
  const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  return cookies ? cookies[1] : ''
}

export default {
  components: { Treeselect, ckeditor: CKEditor.component, vueDropzone: vue2Dropzone, datetime: Datetime },
  name: 'EditDeal',
  validations: {
    newDeal: {
      title: {
        required
      },
      description: {
        required
      },
      url: {
        required
      },
      categories: {
        required
      },
      stores: {
        required
      },
      oldPrice: {
        numeric
      },
      newPrice: {
        required
      },
      images: {
        required
      }
    }

  },
  props: ['routeDealID'],
  data () {
    return {
      dealID: '',
      defaultStores: [],
      selectedDeal: {
        title: '',
        description: '',
        url: '',
        categories: [],
        stores: [],
        oldPrice: '',
        newPrice: '',
        images: [],
        mainImage: '',
        expirationDate: undefined
      },
      newDeal: {
        title: '',
        description: '',
        url: '',
        categories: [],
        stores: [],
        oldPrice: '',
        newPrice: '',
        images: [],
        mainImage: '',
        expirationDate: undefined
      },
      uploadingImage: false,
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
      },
      thumbnailHeap: '',
      dropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + 'images/save_image_raw',
        // url: 'https://httpbin.org/post',
        thumbnailWidth: 300,
        maxFilesize: 1,
        addRemoveLinks: true,
        dictRemoveFile: 'Remove Image',
        resizeWidth: 500,
        headers: { 'x-user': getCookie('user') },
        renameFile: function (file) {
          return new Date().getTime()
        },
        previewsContainer: false
        // previewTemplate: this.dzPreviewTemplate()

      },
      treeCategories: null
    }
  },
  mounted () {
    this.getAllStores(this)
    this.dealID = this.$route.params.id
    this.getDeal(this.dealID)
  },
  computed: {
    newDealCategories: {
      get () {
        // console.log(this.$store.state.Categories.newDealCategories)
        if (this.$store.state.Categories.newDealCategories) { return this.$store.state.Categories.newDealCategories } else return {}
      }
    },
    allCategories: {
      get () {
        // console.log(this.$store.state.Categories.allCategories)
        return this.$store.state.Categories.allCategories
      }
    },
    stores: {
      get () {
        return this.$store.state.Stores.allStores.stores
      }
    }
  },
  methods: {
    ...mapMutations({
      setNewDealStore: 'SET_NEW_DEAL_STORE',
      setNewDealCategory: 'SET_NEW_DEAL_CATEGORY'
    }),
    ...mapActions({
      deleteFile: 'deleteFile',
      addFile: 'addFile',
      getAllCategories: 'getAllCategories',
      getAllStores: 'getAllStores',
      submitDealAction: 'submitDeal',
      getNewDealCategories: 'getNewDealCategories',
      searchForStore: 'searchForStore'
    }),
    sendingEvent (file, xhr, formData) {
      this.uploadingImage = true
      formData.append('submittedBy', getCookie('user'))
      formData.append('thumbnailData', file.dataURL)
      formData.append('type', 'DEAL')
      formData.append('name', file.upload.filename)
      formData.append('id', file.upload.uuid)

      // console.log(file)
    },
    loadStores ({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        if (searchQuery.length >= 2) {
          this.searchForStore({ keyword: searchQuery }).then((data) => {
            const stores = data.stores.map(i => ({ id: i.id, label: i.name }))
            // const options = [ 1, 2, 3, 4, 5 ].map(i => ({
            //   id: `${searchQuery}-${i}`,
            //   label: `${searchQuery}-${i}`
            // }))
            callback(null, stores)
          }

          )
        }
      }
    },
    getMainImageText: function (imageId) {
      // console.log(imageId)
      if (this.newDeal.mainImage === imageId) {
        return 'Main Image'
      } else {
        return 'Set as Main'
      }
    },
    isMainImage: function (imageId) {
      if (this.newDeal.mainImage === imageId) {
        // console.log(imageId)
        return true
      } else {
        return false
      }
    },
    thumbnailEvent (file, dataUrl) {
      var vm = this
      imageTools().thumbnailify(file.dataURL, 200).then((base64) => {
        vm.thumbnailHeap = base64
      })
    },
    removeFile (file, error, xhr) {
      // console.log('deleting file :' + file.upload.uuid)
      const data = {
        id: file.upload.uuid
      }

      this.deleteFile(data)
    },
    removeImage (id) {
      const data = {
        id: id
      }
      // console.log('deleting image :' + id)
      this.deleteFile(data).then((data) => {
        this.newDeal.images = this.newDeal.images.filter(image => image.id !== data.id)
      })
    },
    fileUploaded (file, xhr, formData) {
      this.addFile({ id: file.upload.uuid, fd: xhr.fd })
      if (this.newDeal.images.length === 0) {
        this.newDeal.mainImage = file.upload.uuid
      }
      this.newDeal.images.push({ id: file.upload.uuid, fd: xhr.fd })
      this.uploadingImage = false
    },
    async submitDeal () {
      this.$v.newDeal.$touch()
      if (!this.$v.newDeal.$anyError) {
        const token = await this.$recaptcha('register')
        this.newDeal.captchaToken = token
        this.newDeal.id = this.dealID
        this.submitDealAction(this.newDeal).then((data) => {
          // console.log(data)
          this.$router.push({ name: 'deal', params: { id: data[0].id } })
        })
      }
    },
    async getDeal () {
      Api()
        .get('/deals/get_deal_for_edit', { params: { id: this.dealID } })
        .then(async (data) => {
          // console.log(data.data.deal.expiresAt)
          data.data.deal.expirationDate = new Date(parseInt(data.data.deal.expiresAt)).toISOString()
          data.data.deal.url = data.data.deal.urls[0]
          this.defaultStores = data.data.deal.storesObjects.map(item => { return { label: item.name, id: item.id } })
          // eslint-disable-next-line quotes
          // console.log(this.$refs.storesTreeSelect.forest.nodeMap[0])
          this.defaultStores.forEach(element => {
            // this.$refs.storesTreeSelect.forest.nodeMap[element.id].label = element.label
          })
          this.newDeal = data.data.deal
        })
    },
    dzPreviewTemplate: function () {
      return `
          <div class="dz-default dz-message"><span>Drop files here to upload</span></div>
        `
    },
    loadOptions: async function ({ action, parentNode, callback }) {
      // console.log(action)
      if (action === LOAD_CHILDREN_OPTIONS) {
        await this.getNewDealCategories({ parentID: parentNode.id })
        parentNode.children = this.newDealCategories
        // console.log(parentNode.id)
        // console.log(this.called)
      }
      if (action === LOAD_ROOT_OPTIONS) {
        if (!this.called) {
          await this.getNewDealCategories({})
          this.called = true
          this.treeCategories = this.newDealCategories
          // console.log(this.called)
        } else {
          // console.log(this.called)
          await this.getNewDealCategories({})
        }
      }
    }

  },
  mixins: [validationMixin]
}
</script>

<style scoped>

</style>
