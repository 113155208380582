<template>
  <footer class="footer mb-0 mt-5">
    <div class="container">
      <b-row class="pt-2">
        <b-col cols="6" sm="6" lg="3" class="p-2">
          <h5 class="clearText">Follow Us</h5>
          <b-button size="sm" icon class="w-100 btn-social mb-2" target="_blank" href="https://www.instagram.com/mr.flashsale/">
            <span class="btn-inner--icon mr-1"><font-awesome-icon :icon="['fab', 'instagram']"/></span>
            <span class="btn-inner--text">Instagram</span>
          </b-button><br>
          <b-button href="https://www.facebook.com/mr.flashsale" target="_blank" size="sm" icon class="w-100 btn-social mb-2">
            <span class="btn-inner--icon mr-1"><font-awesome-icon :icon="['fab', 'facebook']"/></span>
            <span class="btn-inner--text">Facebook</span>
          </b-button><br>
          <b-button href="https://twitter.com/flashsale_com" target="_blank" icon size="sm" class="w-100 btn-social mb-2">
            <span class="btn-inner--icon mr-1"><font-awesome-icon :icon="['fab', 'twitter']"/></span>
            <span class="btn-inner--text">Twitter</span>
          </b-button>
        </b-col>
        <b-col sm="6" cols="6" lg="3" class="p-2"><h5 class="clearText">Information</h5>
        <b-button size="sm" icon class="w-100 btn-social mb-2" target="_blank" @click.prevent="showFooterDialog('ABOUT_US')">
            <span class="btn-inner--text">About Us</span>
          </b-button><br>
          <b-button size="sm" icon class="w-100 btn-social mb-2" href="/privacy">
            <span class="btn-inner--text">Privacy Policy</span>
          </b-button><br>
          <b-button icon size="sm" class="w-100 btn-social mb-2" @click.prevent="showFooterDialog('CONTACT_US')">
            <span class="btn-inner--text">Contact Us</span>
          </b-button>
        </b-col>
        <b-col sm="6" cols="12" class="p-3">
          <img width="120" data-v-ed558de2="" src="/assets/images/g24-7.png" alt="Flashsale Logo">
          <small class="clearText d-block my-3">Copyright © 2020-2021. Graphcast® LLC. All Rights Reserved. </small>
        </b-col>
      </b-row>
      <b-row></b-row>
    </div>
    <!-- End .header-bottom -->
    <b-modal id="footer-modal" :hide-footer="currentDialog === 'CONTACT_US'" v-model="shouldShowDialog" :title="modalContent.title">
      <p v-if="currentDialog === 'ABOUT_US'">{{modalContent.body}}</p>
      <div v-if="currentDialog === 'CONTACT_US'">
        <ContactUsForm @close-dialog="closeFooterModal"></ContactUsForm>
      </div>
    </b-modal>
    <b-alert
      v-model="shouldShowSubscriptionDialog"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style=""
      id="subscribe-alert"
      variant="info"
      dismissible
    >
    <b-container>
      <b-row class="text-center"><div class="mx-auto mb-3">Would you like to receive updates about our exciting deals ?</div></b-row>
      <b-row class="text-center">
        <b-col sm="6" class="m-auto">
          <b-form inline id="subscribe-form">
            <b-col sm="9">
              <b-form-group label-for="subscription-email" :class="{ 'form-group--error': $v.subscriptionEmail.$error }">
                <b-form-input type="email" placeholder="Your email" class="w-100 form-input form-wide mx-3" v-model.trim="$v.subscriptionEmail.$model" id="subscription-email" ></b-form-input>
                <b-form-invalid-feedback class="error" :state="!(!$v.subscriptionEmail.email && (this.$v.subscriptionEmail.$dirty))">invalid email address</b-form-invalid-feedback>
                <b-form-invalid-feedback :state="!(!$v.subscriptionEmail.required && (this.$v.subscriptionEmail.$dirty))">
                  Email field is required
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button @click.prevent="subscribe">Subscribe</b-button>
            </b-col>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
    </b-alert>
  </footer>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import Api from '@/services/Api'
import ContactUsForm from './ContactUsForm.vue'
export default {
  name: 'Footer',
  components: {
    ContactUsForm
  },
  mixins: [validationMixin],
  data: function () {
    return {
      shouldShowDialog: false,
      currentDialog: 'ABOUT_US',
      subscriptionModalTitle: 'Subscribe to daily deals',
      shouldShowSubscriptionDialog: false,
      email: '',
      subscriptionEmail: ''
    }
  },
  validations: {
    subscriptionEmail: {
      required,
      email
    },
    email: {
      required,
      email,
      minLength: minLength(5)
    }
  },
  mounted () {
    // console.log(this.$cookies.get('subDialogShown'))
    if (!this.user.loggedInUser.id && !this.$cookies.get('subDialogShown')) {
      this.shouldShowSubscriptionDialog = true
      this.$cookies.set('subDialogShown', Date.now(), '30d')
    }
  },
  computed: {
    user: {
      get () {
        return this.$store.state.User.user
      }
    },
    isUserAuthenticated: {
      get () {
        return this.$store.state.isUserAuthenticated
      }
    },
    modalContent: {
      get () {
        var object = { title: 'About Us', body: '' }
        switch (this.currentDialog) {
          case 'ABOUT_US':
            object.title = 'About Us'
            object.body = 'Flashsale.com is a community maintained website for daily deals from different online stores. Please subscribe to our newsletter if you\'re interested in receiving hand picked deals.'
            break
          case 'PRIVACY_POLICY':
            object.title = 'Privacy Policy'
            object.body = 'Privacy Policy'
            break
          case 'CONTACT_US':
            object.title = 'Contact Us'
            break
        }
        return object
      }
    }
  },
  methods: {
    closeFooterModal: function () {
      this.$bvModal.hide('footer-modal')
    },
    subscribe: async function () {
      this.$v.$touch()
      Api()
        .get('/subscriptions/subscribe', { params: {
          emailAddress: this.subscriptionEmail } })
        .then((data) => {
          this.$cookies.set('subDialogShown', Date.now(), '30d')
          this.shouldShowSubscriptionDialog = false
          this.$toasted.show('Thank you for subscribing !!',
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        })
    },
    getCookie: name => {
      const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
      return cookies ? cookies[1] : ''
    },
    showFooterDialog (dialogCode) {
      this.currentDialog = dialogCode
      this.shouldShowDialog = true
    },
    hideFooterDialog () {
      this.shouldShowDialog = false
      this.currentDialog = 'ABOUT_US'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
