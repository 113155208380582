<template>
  <b-form  >
      <b-form-group label-for="sender-name" label="Name" :class="{ 'form-group--error': $v.contact.name.$error }">
            <b-form-input type="text" placeholder="Your Name" class="form-input form-wide mb-2" v-model.trim="$v.contact.name.$model" id="sender-name" ></b-form-input>
            <b-form-invalid-feedback :state="(!this.$v.contact.name.$dirty)||(this.$v.contact.name.required)">
              Please enter your name.
            </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label-for="sender-email" label="Email" :class="{ 'form-group--error': $v.contact.email.$error }">
            <b-form-input type="text" placeholder="Your Email Address" class="form-input form-wide mb-2" v-model.trim="$v.contact.email.$model" id="sender-email" ></b-form-input>
            <b-form-invalid-feedback :state="(!this.$v.contact.email.$dirty)||(this.$v.contact.email.required)">
              Please enter your email address.
            </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label-for="sender-message" label="Message" :class="{ 'form-group--error': $v.contact.message.$error }">
            <b-form-textarea type="text" placeholder="Your Message" class="form-input form-wide mb-2" v-model.trim="$v.contact.message.$model" id="sender-message" ></b-form-textarea>
            <b-form-invalid-feedback :state="(!this.$v.contact.message.$dirty)||(this.$v.contact.message.required)">
              Please enter your message.
            </b-form-invalid-feedback>
      </b-form-group>
      <b-row>
        <b-col >
          <b-button variant="primary" class="float-right mr-2" @click.prevent="onSubmit">Send</b-button>
          <b-button class="float-right mr-2"  @click.prevent="onCancel">Cancel</b-button>
        </b-col>
      </b-row>
  </b-form>
</template>

<script>
import Api from '@/services/Api'
import { required } from 'vuelidate/lib/validators'
export default {
  components: { },
  name: 'ContactUsForm',
  props: ['userID'],
  data () {
    return {
      contact: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  validations: {
    contact: {
      name: { required },
      email: { required },
      message: { required }
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    onCancel: function () {
      this.$emit('close-dialog')
    },
    onSubmit: async function (bvModalEvt) {
      bvModalEvt.preventDefault()
      // send_contact_us_msg?senderEmail=b.allaeddine@gmail.com&senderName=salut&body=hello
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const token = await this.$recaptcha('login')
        var self = this
        Api()
          .post('/messages/send_contact_us_msg', { senderEmail: this.contact.email, senderName: this.contact.name, body: this.contact.message, captchaToken: token })
          .then((data) => {
            if (data.data === 'MESSAGE_SENT') {
              self.$emit('close-dialog')
              this.$toasted.show('Your message has been sent',
                { onComplete: function () {
                  // console.log('toast completed')
                } }).goAway(2000)
            } else {
              self.$emit('close-dialog')
              this.$toasted.show('an error has occured',
                { onComplete: function () {
                  // console.log('toast completed')
                } }).goAway(2000)
            }
          })
      }
    }
  },
  mixins: []
}
</script>

<style scoped>

</style>
