export default {
  SET_FEATURED_CATEGORIES (state, data) {
    // console.log(data)
    state.featuredCategories = data
  },
  SET_ALL_CATEGORIES (state, data) {
    // console.log(data)
    state.allCategories = data
  },
  SET_FILTERED_CATEGORIES (state, data) {
    // console.log(data)
    state.filteredCategories = data
  },
  SET_NEW_DEAL_CATEGORIES (state, data) {
    // console.log(data)
    state.newDealCategories = data
  }

}
