export default {

  SET_ALL_STORES (state, data) {
    // console.log(data)
    state.allStores = data
  },
  SET_FILTERED_STORES (state, data) {
    // console.log(data)
    state.filteredStores = data
  },
  SET_STORE_FILTER (state, data) {
    // console.log(data)
    state.storeFilter = data
  }

}
