export default {
  newDeal: {
    categories: [],
    stores: [],
    title: '',
    url: '',
    description: '',
    newPrice: 0.0,
    oldPrice: 0.0,
    expirationDate: '',
    images: []
  },
  selectedDeal: {
    categories: [],
    stores: [],
    title: '',
    url: '',
    likedBy: '',
    dislikedBy: '',
    description: 'Loading...',
    newPrice: 0.0,
    oldPrice: 0.0,
    images: []
  }

}
