import { mapMutations } from 'vuex'
import { required, minLength, email } from 'vuelidate/lib/validators'
export default {
  computed: {
    user: {
      get () {
        return this.$store.state.User.user
      }
    },

    email: {
      get () {
        return this.$store.state.User.user.email
      },

      set (email) {
        this.$store.commit('SET_EMAIL', email)
      }
    },

    password: {
      get () {
        return this.$store.state.User.user.password
      },

      set (password) {
        this.$store.commit('SET_USER_PASSWORD', password)
      }
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(5)
    }
  },
  data: function () {
    return {
      errors: []
    }
  },
  methods: {
    async signIn () {
      await this.$recaptchaLoaded()
      // Execute reCAPTCHA with action "login".
      this.errors.length = 0
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const token = await this.$recaptcha('login')
        this.isLoading = true
        await this.$store.dispatch('signIn', { emailAddress: this.user.email, password: this.user.password, captchaToken: token }).then((data) => {
          this.isLoading = false
          window.localStorage.setItem('x-token', data.authToken)
          this.setCookie('user', data.id, 3600 * 24 * 7)
          this.$nextTick(() => {
            // console.log('user logged in')
            this.$emit('user-logged-in')
          })
          // this.$emit('authPageChanged', 'LOGGED_IN')
        }).catch((error) => {
          this.isLoading = false
          if (error.response.status === 401) {
            this.errors.push({ message: 'incorrect email or password', id: error.response.status })
          } else {
            this.errors.push({ message: 'an error has occurred', id: error.response.status })
          }
        })
      }

      // this.$router.push({ name: 'Products' })
    },

    setCookie (name, value, seconds) {
      const expires = seconds ? '; expires=' + new Date(new Date().getTime() + seconds * 1000).toGMTString() : ''
      document.cookie = name + '=' + encodeURIComponent(value) + expires + '; path=/'
    },

    ...mapMutations({
      setIsUserAuthenticated: 'SET_IS_USER_AUTHENTICATED',
      setLoggedInUser: 'SET_LOGGED_IN_USER'
    })
  }
}
