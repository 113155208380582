<template>
  <div>
    <b-table :fields='tableFields' striped hover :items='formattedCollections'>
        <template #cell(actions)="row">
            <a size="sm" href="#" @click.prevent="editCollection(row)" class="mr-2">
                <font-awesome-icon :icon="['fas','edit']" />
            </a>
        </template>
    </b-table>
    <b-row class="justify-content-md-center">
        <b-pagination
        v-model="currentPage"
        :total-rows="numberOfCollections"
        :per-page="collectionsPerPage"
        aria-controls="my-table"
        ></b-pagination>
    </b-row>
  </div>
</template>

<script>
import Api from '@/services/Api'
export default {
  name: 'CollectionsAdmin',
  props: {
    msg: String
  },
  data: function () {
    return {
      numberOfCollections: 0,
      currentPage: 1,
      collectionsPerPage: 8,
      collectionsData: [],
      formattedCollections: [],
      tableFields: ['name', 'tag', 'status', 'actions']
    }
  },
  watch: {

    collectionsData: {
      deep: true,
      handler () {
        this.formattedCollections = this.collectionsData.map(function (item) {
          return {
            name: item.title,
            tag: item.tag,
            status: item.status
          }
        })
      }

    }
  },
  mounted: function () {
    this.getCollections()
  },
  methods: {
    getCollections: function () {
      Api().get('/collections/collections', { params: { start: (this.currentPage - 1) * this.collectionsPerPage, limit: this.collectionsPerPage } }).then((data) => {
        // console.log(data.data.result)
        this.collectionsData = data.data.result
        this.numberOfCollections = data.data.count
      })
        .catch(error => {
          this.$toasted.show(error.message,
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        })
    },
    toggleSliderStatus: function (params) {
    },
    editCollection: function (item) {
      // console.log(item)
      var collection = this.collectionsData[item.index]
      // console.log(collection)
      this.$router.push({ name: 'collectionAdmin', params: { id: collection.id } })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
