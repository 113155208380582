<template>
  <div class="widget widget-featured">
    <h3 class="widget-title">Featured Products</h3>

    <div class="widget-body">
      <div >
        <b-carousel v-bind:per-page="1" :paginationEnabled="false" :navigateTo="0" :navigationEnabled="true">
          <b-carousel-slide v-for="(column,index) in this.featuredDeals" :key="index" >
            <div class="featured-col">
              <div v-for="(card,index2) in column" :key="index2" class="product-default left-details product-widget">
                <figure>
                  <a :href="'/deal/'+card.id">
                    <img :src="'https://s3.amazonaws.com/images.flashsale.com/'+card.thumbnail">
                  </a>
                </figure>
                <div class="product-details">
                  <h2 class="product-title">
                    <a v-bind:href="'/deal/'+card.id">{{card.title}}</a>
                  </h2>
                  <div class="ratings-container">
                    <div class="product-ratings">
                      <span class="ratings" :style="'width:'+card.rating+'%'"></span><!-- End .ratings -->
                      <span class="tooltiptext tooltip-top">{{card.rating ===0 ? 'Not Rated Yet':card.rating+'%'}}</span>
                    </div><!-- End .product-ratings -->
                  </div><!-- End .product-container -->
                  <div class="price-box">
                    <span class="old-price">${{card.oldPrice}}</span>
                    <span class="product-price">${{card.newPrice}}</span>
                  </div><!-- End .price-box -->
                </div><!-- End .product-details -->
              </div>
            </div>

          </b-carousel-slide>
        </b-carousel >
        <!-- End .featured-col -->

      </div><!-- End .widget-featured-slider -->
    </div><!-- End .widget-body -->
  </div>
</template>

<script>
// import the component
import { mapActions } from 'vuex'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
/* const getCookie = name => {
  const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  return cookies ? cookies[1] : ''
} */

export default {
  components: {},
  name: 'FeaturedDealsColumn',
  validations: {
  },
  data () {
    return {
      loading: false,
      navigateTo: 0
    }
  },
  created () {
    this.getWidgetFeaturedDeals({ page: 1, count: 6 })
  },
  computed: {
    featuredDeals: {
      get () {
        return this.$store.state.Deals.widgetFeaturedDeals
      }
    }
  },
  methods: {

    ...mapActions(['getWidgetFeaturedDeals'])
  },
  mixins: []
}
</script>

<style scoped>

</style>
