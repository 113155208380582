<template>
  <main class="main search-page">
    <div class="container">
      <div class="row">
        <ResultList></ResultList>
        <FiltersColumn></FiltersColumn>
      </div>

    </div>
  </main>
</template>

<script>
// import the component

// import the styles
import ResultList from '@/components/search/ResultList.vue'
import FiltersColumn from '@/components/search/FiltersColumn.vue'

/* const getCookie = name => {
  const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  return cookies ? cookies[1] : ''
} */

export default {
  components: {
    ResultList,
    FiltersColumn },
  name: 'SearchPage',
  validations: {

  },
  data () {
    return {
      loading: false,
      navigateTo: 0
    }
  },
  created () {

  },
  computed: {

  },
  methods: {

  },
  mixins: []
}
</script>

<style scoped>

</style>
