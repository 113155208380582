export default {
  SET_HEADER_VISIBILITY (state, visibility) {
    state.shouldShowHeader = visibility
  },
  SET_AUTH_DIALOG_VISIBILITY (state, visibility) {
    state.shouldShowAuth = visibility
  },
  SET_MOBILE_FILTER_BAR_SHOWN (state, visibility) {
    state.mobileFilterBarShown = visibility
  }

}
