<template>
  <div class="col-md-12">
    <div class="form-error-container">
      <div v-bind:key="index" class="error" v-for="(error,index) in errors">{{error.message}}</div>
    </div>
    <div>
      <p>An email has been sent with a link and access code to activate your account. <br/>Please click on the link or enter the provided verification code to confirm your email.   </p>
    </div>
    <form action="#">
      <b-form-group label-for="verification-code" label="Verification code" :class="{ 'form-group--error': $v.verificationCode.$error }">
        <b-form-input placeholder="Enter code" class="form-input form-wide mb-2" v-model.trim="$v.verificationCode.$model" id="verification-code" ></b-form-input>
        <b-form-invalid-feedback :state="(!this.$v.verificationCode.$dirty)||(this.$v.verificationCode.required)">
          Verification code is required
        </b-form-invalid-feedback>
      </b-form-group>
      <footer class="modal-footer px-0">
        <b-row class="w-100">
          <b-col class="px-0">
            <b-button
            variant="warning"
            class="float-left" @click.prevent="resendCode">RESEND CODE</b-button>
          </b-col>
          <b-col class="px-0">
            <b-button
            variant="primary"
            class="float-right" @click.prevent="verifyEmail">VERIFY</b-button>
          </b-col>
        </b-row>
      </footer>
    </form>
  </div>

</template>

<script>
import { validationMixin } from 'vuelidate'
import ConfirmEmail from './ConfirmEmail.mixin'

export default {
  name: 'ConfirmEmail',
  mixins: [validationMixin, ConfirmEmail]
}
</script>

<style scoped>

</style>
