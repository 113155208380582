<template>
  <b-overlay class="col-lg-9" :show="this.loading" rounded="sm">
    <div >
      <!--<div class="row" style="margin-left: 0px; margin-right: 0px;margin-bottom: 15px">
        <button v-if="storeFilter.id" class="btn btn-light btn-sm" v-on:click.prevent="removeStoreFilter(storeFilter.id)" style="border: 1px solid #e2e2e2;">{{storeFilter.name}}<span style="margin-left: 10px"><font-awesome-icon :icon="['far', 'minus-square']" /></span></button>
        <button v-if="!(JSON.stringify(categoryFilter) === '{}')" class="btn btn-light btn-sm" v-on:click.prevent="removeCategoryFilter(categoryFilter.id)" style="border: 1px solid #e2e2e2;">{{categoryFilter.label}}<span style="margin-left: 10px"><font-awesome-icon :icon="['far', 'minus-square']" /></span></button>
      </div>-->
      <b-row v-if="this.loading">Loading</b-row>
      <b-row class="my-4">
        <b-col class="h-100">
          <b-form-select v-model="sortBy" class="w-auto" name="orderby">
            <b-form-select-option value="CREATION_DATE" selected="selected">Recent Deals First</b-form-select-option>
            <b-form-select-option value="DISCOUNT_PERCENTAGE">Best Deals First</b-form-select-option>
            <b-form-select-option value="RATING">Popularity</b-form-select-option>
          </b-form-select></b-col>
        <b-col class="h-100" id="search-position">
          <label class="float-right">Showing {{start+1 + ' - ' + (lastShownDealIndex)}} of {{filteredDeals.totalCount}} results</label>
        </b-col>
        <b-col class="mobile-filter-toggle"><b-button @click="toggleMobileFilterBar" class="w-100"><i class="icon-sliders"></i>Filters</b-button></b-col>
        <div class="overlay" v-if="mobileFilterBarShown">overlay</div>
      </b-row>
      <div class="row row-sm" >
        <b-card-group style="width:100%">
          <div v-bind:key="e.id" v-for="(e) in filteredDeals.deals" class="col-lg-4 col-md-4 col-sm-12">
            <deal-card :deal="e"></deal-card>
          </div>
        </b-card-group>
      </div>

      <div class="row justify-content-center deals-list-pagination" v-if="filteredDeals.totalCount">
        <b-pagination  :total-rows="filteredDeals.totalCount" :per-page="9" align="fill" v-model="page"></b-pagination>
      </div>
    </div>
  </b-overlay>
</template>

<script>
// import the component
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { mapActions } from 'vuex'
import DealCard from '../deal/DealCard'

/* const getCookie = name => {
  const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  return cookies ? cookies[1] : ''
} */

export default {
  components: { DealCard },
  name: 'ResultList',
  validations: {

  },
  data () {
    return {
      loading: false,
      navigateTo: 0,
      dealsPerPage: 9,
      page: 1,
      sortBy: 'CREATION_DATE',
      mounted: false
    }
  },
  mounted () {
    var routeCategory = this.$router.currentRoute.params.category
    var routeStore = this.$router.currentRoute.params.store
    var routeKeyword = this.$router.currentRoute.params.keyword
    if (routeCategory !== '_') { this.setFilterCategoryByID(routeCategory) }
    if (routeStore !== '_') { this.setFilterStoreByID(routeStore) }
    if (routeKeyword !== '_') { this.keywordFilter = routeKeyword }
    document.title = 'Flashsale - Find deals'
    var vm = this
    this.loading = true
    this.getFilteredStores({
      ...routeStore !== '_' && { id: routeStore },
      ...routeCategory !== '_' && { categories: [routeCategory] },
      ...routeKeyword !== '_' && { keyword: routeKeyword } }).then(function (data) {
      vm.getFilteredCategories({
        // ...routeCategory !== '_' && { parentID: routeCategory },
        ...routeKeyword !== '_' && { keyword: routeKeyword },
        ...routeStore !== '_' && { stores: [routeStore] } }
      ).then(function (data) {
        // console.log(vm.keywordFilter)
        vm.getFilteredDeals({ ...vm.storeFilter.id && { stores: [vm.storeFilter.id] },
          keyword: vm.keywordFilter,
          ...vm.categoryFilter.id && { categories: [vm.categoryFilter.id] },
          start: vm.start,
          limit: vm.dealsPerPage,
          sortBy: vm.sortBy }).then(() => {
          // console.log('loading ended')
          vm.loading = false
        })
        vm.mounted = true
      })
    })
  },
  created () {
  },
  watch: {
    globalFilter: function (newFilter, oldFilter) {
      if (!this.mounted) {
        return
      }
      this.loading = true
      // console.log('loading started')
      this.getFilteredDeals({
        ...this.globalFilter.storesFilter.length > 0 && { stores: this.globalFilter.storesFilter },
        keyword: this.globalFilter.keywordFilter,
        ...this.globalFilter.categoriesFilter.length > 0 && { categories: this.globalFilter.categoriesFilter },
        start: this.start,
        limit: this.dealsPerPage,
        sortBy: this.sortBy })
      if (newFilter.categoriesFilter !== oldFilter.categoriesFilter || newFilter.keywordFilter !== oldFilter.keywordFilter) {
        this.getFilteredStores({
          ...newFilter.categoriesFilter.length > 0 && { categories: newFilter.categoriesFilter },
          ...newFilter.keywordFilter.length > 0 && { keyword: newFilter.keywordFilter } }).then(() => {
          this.getFilteredCategories({
            ...newFilter.storesFilter.length > 0 && { stores: newFilter.storesFilter },
            ...newFilter.keywordFilter.length > 0 && { keyword: newFilter.keywordFilter } })
        }).then(() => {
          // console.log('loading ended')
          this.loading = false
        })
      }

      if (newFilter.storesFilter !== oldFilter.storesFilter) {
        this.getFilteredCategories({
          ...newFilter.storesFilter.length > 0 && { stores: newFilter.storesFilter },
          ...newFilter.keywordFilter.length > 0 && { keyword: newFilter.keywordFilter } }).then(() => {
          this.loading = false
        })
      }
    },
    sortBy: function (newSortBy, oldSortBy) {
      this.loading = true
      this.getFilteredDeals({
        ...this.globalFilter.storesFilter.length > 0 && { stores: this.globalFilter.storesFilter },
        keyword: this.globalFilter.keywordFilter,
        ...this.globalFilter.categoriesFilter.length > 0 && { categories: this.globalFilter.categoriesFilter },
        start: this.start,
        limit: this.dealsPerPage,
        sortBy: this.sortBy }).then(() => {
        this.loading = false
      })
    },
    page: function (newCategory, oldCategory) {
      // console.log('category changed:' + newCategory)
      this.getFilteredDeals({
        ...this.globalFilter.storesFilter.length > 0 && { stores: this.globalFilter.storesFilter },
        keyword: this.globalFilter.keywordFilter,
        ...this.globalFilter.categoriesFilter.length > 0 && { categories: this.globalFilter.categoriesFilter },
        start: this.start,
        limit: this.dealsPerPage,
        sortBy: this.sortBy }).then(() => {
        this.loading = false
      })
    }
  },
  computed: {
    start: function () { return (this.page - 1) * this.dealsPerPage },
    storeFilter: {
      get () {
        // console.log(this.$store.state.Filters.storeFilter)
        return this.$store.state.Filters.storeFilter
      },
      set (store) {
        this.$store.commit('SET_STORE_FILTER', store.id)
      }
    },
    globalFilter: {
      get () {
        // console.log(this.$store.state.Filters.globalFilter)
        return this.$store.state.Filters.globalFilter
      }
    },
    categoryFilter: {
      get () {
        // console.log(this.$store.state.Filters.categoryFilter)
        return this.$store.state.Filters.categoryFilter
      },
      set (category) {
        // console.log('category' + category)
        this.$store.commit('SET_CATEGORY_FILTER', category.id)
      }
    },
    filteredDeals: {
      get () {
        // console.log(this.$store.state.Deals.filteredDeals)
        return this.$store.state.Deals.filteredDeals
      },
      set (deals) {
        this.$store.commit('SET_FILTERED_DEALS', deals)
      }
    },
    keywordFilter: {
      get () {
        // console.log(this.$store.state.Filters.keywordFilter)
        return this.$store.state.Filters.keywordFilter
      },
      set (keyword) {
        this.$store.commit('SET_KEYWORD_FILTER', keyword)
      }
    },
    mobileFilterBarShown: {
      get () {
        // console.log(this.$store.state.Filters.keywordFilter)
        return this.$store.state.Global.mobileFilterBarShown
      }
    },
    lastShownDealIndex: function () {
      return (this.start + this.dealsPerPage) <= this.filteredDeals.totalCount ? (this.start + this.dealsPerPage)
        : this.filteredDeals.totalCount
    }

  },
  methods: {
    ...mapActions({
      removeStoreFilter: 'removeStoreFilter',
      removeCategoryFilter: 'removeCategoryFilter',
      getFilteredDeals: 'getFilteredDeals',
      getFilteredStores: 'getFilteredStores',
      setFilterStoreByID: 'setFilterStoreByID',
      setFilterCategoryByID: 'setFilterCategoryByID',
      setFilterKeyword: 'setFilterKeyword',
      getFilteredCategories: 'getFilteredCategories',
      toggleMobileFilterBar: 'toggleMobileFilterBar'

    })
  },
  mixins: []
}
</script>

<style scoped>

</style>
