import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './state/store'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import TreeView from 'vue-json-tree-view'
import Toasted from 'vue-toasted'
import VueCookies from 'vue-cookies'
import VueAxios from 'vue-axios'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee,
  faThumbsUp as fasThumbsUp,
  faThumbsDown as fasThumbsDown,
  faHeart as fasHeart, faTrash,
  faMinusSquare,
  faShareAlt as fasShareAlt,
  faExternalLinkAlt,
  faEye as fasEye,
  faWindowClose as fasWindowClose,
  faTags as fasTags,
  faMedal as fasMedal,
  faComments as fasComments,
  faAward as fasAward,
  faTrash as fasTrash,
  faBars as fasBars,
  faSearch as fasSearch,
  faReply as fasReply,
  faFlag as fasFlag,
  faEdit as fasEdit,
  faHome as fasHome
} from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

import { faHeart, faThumbsUp, faThumbsDown, faMinusSquare as farMinusSquare, faPlayCircle as farPlayCircle, faStopCircle as farStopCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../public/assets/sass_v2/style.scss'
import { VueReCaptcha } from 'vue-recaptcha-v3'

library.add(faThumbsUp,
  faCoffee,
  faThumbsDown,
  faHeart,
  fasHeart,
  fasThumbsUp,
  fasThumbsDown,
  faTrash,
  faMinusSquare,
  farMinusSquare,
  fasShareAlt,
  faExternalLinkAlt,
  fasWindowClose,
  fasTags,
  fasMedal,
  fasAward,
  fasEye,
  fasComments,
  farStopCircle,
  fasTrash,
  farPlayCircle,
  fasBars,
  fasSearch,
  fasFlag,
  fasReply,
  fasEdit,
  faInstagram,
  faFacebook,
  faTwitter,
  fasHome)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Datetime)
Vue.use(Toasted)
Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(TreeView)
Vue.use(VueReCaptcha, { siteKey: '6LcN3ScaAAAAAB7A0oJJAwVvqj6gKLHBg-mshfdb' })

Vue.config.productionTip = false
VueCookies.config('7d')

router.beforeEach((to, from, next) => {
  const getCookie = name => {
    const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
    return cookies ? cookies[1] : ''
  }
  if (to.matched.some(record => record.meta.userOnly)) {
    if (getCookie('user')) {
      next()
    } else router.push({ name: 'auth' })
  } else next()
})
new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
  }
}).$mount('#app')

/* eslint-disable no-new */
