
export default {
  setHeaderVisibility (context, visibility) {
    context.commit('SET_HEADER_VISIBILITY', visibility)
  },
  setAuthDialogVisibility (context, visibility) {
    context.commit('SET_AUTH_DIALOG_VISIBILITY', visibility)
  },
  toggleMobileFilterBar (context) {
    context.commit('SET_MOBILE_FILTER_BAR_SHOWN', !context.state.mobileFilterBarShown)
  }
}
