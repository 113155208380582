import Api from '@/services/Api'

export default {
  getFeaturedDeals (context, params) {
    // console.log(params)
    return new Promise((resolve, reject) => {
      Api().get('/collections/collections', { params: { tag: 'FEATURED_DEALS', start: 0, limit: 1 } }).then((response) => {
        Api()
          .get('/collections/deals', { params: { start: (params.page - 1) * params.count, limit: params.count, id: response.data.result[0].id } })
          .then(({ data }) => {
          // console.log(data)
            context.commit('SET_FEATURED_DEALS', data)
            resolve()
          })
          .catch(error => reject(error))
      })
    })
  },
  getWidgetFeaturedDeals (context, params) {
    // console.log(params)
    return new Promise((resolve, reject) => {
      Api()
        .get('/collections/deals', { params: { start: (params.page - 1) * params.count, limit: params.count, id: context.state.featuredDealCollectionID } })
        .then(({ data }) => {
          // console.log(data)
          context.commit('SET_WIDGET_FEATURED_DEALS', data)
          resolve()
        })
        .catch(error => reject(error))
    })
  },
  getEditorsChoiceDeals (context, page) {
    return new Promise((resolve, reject) => {
      // console.log(context)
      Api().get('/collections/collections', { params: { tag: 'EDITORS_CHOICE', start: 0, limit: 1 } }).then((response) => {
        Api()
          .get('/collections/deals', { params: { start: (page - 1) * 4, limit: 4, id: response.data.result[0].id } })
          .then(({ data }) => {
          // console.log(data)
            context.commit('SET_EDITORS_CHOICE_DEALS', data)
            resolve()
          })
          .catch(error => reject(error))
      })
    })
  },
  getFilteredDeals (context, data) {
    return new Promise((resolve, reject) => {
      Api()
        .get('/deals/filtered_deals', {
          params: {
            start: data.start,
            limit: data.limit,
            ...data.stores && data.stores.length > 0 && { stores: data.stores },
            ...data.keyword && data.keyword.length > 0 && { keyword: data.keyword },
            ...data.categories && data.categories.length > 0 && { categories: data.categories },
            sortBy: data.sortBy } })
        .then(({ data }) => {
          // console.log(data)
          context.commit('SET_FILTERED_DEALS', data)
          resolve()
        })
        .catch(error => reject(error))
    })
  }
}
