<template>
  <div>
    <b-row class="float-right my-3">
      <b-button v-b-modal.modal-1 variant="outline-primary" @click.prevent='addDeal'><b>+ </b> Add Deal</b-button>
    </b-row>
    <b-table :fields='tableFields' striped hover :items='formattedDeals'>
      <template #cell(name)="row">
            <a href="#" @click.prevent="openDeal(row)" target="blank" class="mr-2">
                {{row.item.name}}
            </a>
        </template>
        <template #cell(actions)="row">
            <a size="sm" href="#" @click.prevent="toggleSlideStatus(row)" class="mr-2">
                <font-awesome-icon :icon="row.item.status == 'ACTIVE'? ['far','stop-circle']:['far','play-circle']" />
            </a>
            <a v-b-modal.delete-deal-modal size="sm" href="#" @click.prevent="showRemoveDealDialog(row)" class="mr-2">
                <font-awesome-icon :icon="['fas','trash']" />
            </a>
        </template>
        <template #cell(image)="row">
            <img size="sm" style="max-height: 45px;" :src="'https://s3.amazonaws.com/images.flashsale.com/'+row.item.image" @click.prevent="toggleSlideStatus(row)" class="mr-2">
        </template>
    </b-table>
    <b-row class="justify-content-md-center">
        <b-pagination
        v-model="currentPage"
        :total-rows="numberOfDeals"
        :per-page="dealsPerPage"
        aria-controls="my-table"
        ></b-pagination>
    </b-row>
    <b-modal id="modal-1" @ok="confirmAddDeal" title="Edit Slide">
      <b-form>
        <b-form-group label-for="deal-id" label="Deal ID :" :class="{ 'form-group--error': $v.editedDeal.id.$error }">
          <b-form-input type="text" placeholder="Deal ID" class="form-input form-wide mb-2" v-model.trim="$v.editedDeal.id.$model" id="deal-id" ></b-form-input>
          <b-form-invalid-feedback :state="(!this.$v.editedDeal.id.$dirty)||(this.$v.editedDeal.id.required)">
            ID field is required
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal id="delete-deal-modal" @ok="deleteDeal" title="Delete Slide ?">
      <p class="my-4">Are you sure to delete this deal ?</p>
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Api from '@/services/Api'
export default {
  name: 'CollectionAdmin',
  props: {
    msg: String
  },
  validations: {
    editedDeal: {
      id: { required }
    }
  },
  data: function () {
    return {
      numberOfDeals: 0,
      currentPage: 1,
      dealsPerPage: 8,
      isNewDeal: false,
      collectionID: '',
      collectionDealsData: [],
      formattedDeals: [],
      tableFields: ['name', 'status', 'actions'],
      editedDeal: {
        id: ''
      }
    }
  },
  watch: {

    collectionDealsData: {
      deep: true,
      handler () {
        this.formattedDeals = this.collectionDealsData.map(function (item) {
          return {
            name: item.title,
            tag: item.tag,
            status: item.status
          }
        })
      }
    },
    currentPage: function (page) {
      this.getCollectionDeals()
    }
  },
  mounted: function () {
    this.collectionID = this.$route.params.id
    this.getCollectionDeals()
  },
  methods: {
    getCollectionDeals: function () {
      Api().get('/collections/deals', { params: { id: this.collectionID, start: (this.currentPage - 1) * this.dealsPerPage, limit: this.dealsPerPage } }).then((data) => {
        // console.log(data.data.result)
        this.collectionDealsData = data.data.result
        this.numberOfDeals = data.data.count
      })
        .catch(error => {
          this.$toasted.show(error.message,
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        })
    },
    submitCollection: function () {},
    deleteDeal: function () {
      Api().get('/collections/add_delete_deal', { params: {
        collection: this.collectionID,
        deals: [this.editedDeal.id],
        submitter: this.$cookies.get('user'),
        operation: 0
      } }).then((data) => {
        this.getCollectionDeals()
      })
        .catch(error => {
          this.$toasted.show(error.message,
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        })
    },
    confirmAddDeal: function (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (!this.$v.$invalid) {
        Api().get('/collections/add_delete_deal', { params: {
          collection: this.collectionID,
          deals: [this.editedDeal.id],
          submitter: this.$cookies.get('user'),
          operation: 1
        } }).then((data) => {
          this.getCollectionDeals()
          this.$nextTick(() => {
            this.$bvModal.hide('modal-1')
          })
        })
          .catch(error => {
            this.$toasted.show(error.message,
              { onComplete: function () {
                // console.log('toast completed')
              } }).goAway(4000)
          })
      } else {
        // console.log(this.$v.$invalid)
        return false
      }
    },
    openDeal: function (item) {
      var deal = this.collectionDealsData[item.index]
      let routeData = this.$router.resolve({ name: 'deal', params: { id: deal.id } })
      window.open(routeData.href, '_blank')
      // this.$router.push({ name: 'deal', params: { id: deal.id } })
    },
    addDeal: function (item) {
      this.$v.$reset()
      this.editedDeal = { id: '' }
    },
    toggleSliderStatus: function (params) {
    },
    showRemoveDealDialog: function (item) {
      var deal = this.collectionDealsData[item.index]
      // console.log(deal)
      this.editedDeal = deal
      this.isNewDeal = false
    },
    editCollection: function (item) {
      // console.log(item)
      var collection = this.collectionsData[item.index]
      // console.log(collection)
      this.$router.push({ name: 'collectionAdmin', params: { id: collection.id } })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
