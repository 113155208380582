<template>
  <div>
    <div class="row justify-content-center" >
    <b-card-group deck  v-for="(editorChoiceDealsRow, index) in editorsChoiceDeals" :key="'row-'+index" >
      <deal-card :id="editorChoiceDeal.id" :deal="editorChoiceDeal" v-for="editorChoiceDeal in editorChoiceDealsRow" :key="editorChoiceDeal.id"></deal-card>
    </b-card-group>
    </div>
    <div class="row w-100 justify-content-center" v-if="numberOfEditorsChoiceDeals">
      <b-pagination  :total-rows="numberOfEditorsChoiceDeals" :per-page="4" align="fill" v-model="currentPage"></b-pagination>
    </div>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
import DealCard from '../deal/DealCard'
export default {
  components: { DealCard },
  created () {
    this.getEditorsChoiceDeals(this.currentPage)
  },
  name: 'EditorsChoiceDeals',
  data: () => ({
    currentPage: 1
  }),
  watch: {
    currentPage () {
      this.getEditorsChoiceDeals(this.currentPage)
    }
  },
  computed: {
    numberOfEditorsChoiceDeals: {
      get () {
        return this.$store.state.Deals.numberOfEditorsChoiceDeals
      }
    },
    editorsChoiceDeals: {
      get () {
        if (this.$store.state.Deals.editorsChoiceDeals.length > 0) {
          return this.$store.state.Deals.editorsChoiceDeals.reduce((c, n, i) => {
            if (i % 4 === 0) c.push([])
            c[c.length - 1].push(n)
            return c
          }, [])
        } else {
          return []
        }
      }
    }
  },
  methods: {

    ...mapActions(['getEditorsChoiceDeals'])
  }
}
</script>

<style scoped>

</style>
