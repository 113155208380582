// import Initializer from '@/services/Initializers'
import Vue from 'vue'
export default {

  SET_NEW_DEAL_STORE (state, store) {
    state.newDeal.stores = store
  },
  SET_NEW_DEAL_CATEGORY (state, categories) {
    // console.log(state)
    state.newDeal.categories = categories
  },
  SET_NEW_DEAL_URL (state, url) {
    // console.log(state)
    state.newDeal.url = url
  },
  SET_NEW_DEAL_TITLE (state, title) {
    state.newDeal.title = title
  },
  SET_NEW_DEAL_DESCRIPTION (state, description) {
    state.newDeal.description = description
  },
  SET_NEW_DEAL_PRICE (state, data) {
    // console.log(data)
    state.newDeal.newPrice = data
  },
  SET_NEW_DEAL_IMAGES (state, data) {
    // console.log(data)
    state.newDeal.images = data
  },
  ADD_NEW_DEAL_IMAGE (state, data) {
    // console.log(state.newDeal.images)
    state.newDeal.images.push(data)
  },
  REMOVE_NEW_DEAL_IMAGE (state, data) {
    // console.log(data.id)
    // console.log('splicing element at ' + state.newDeal.images.indexOf(data.id))
    state.newDeal.images.splice(state.newDeal.images.indexOf(data.id), 1)
  },
  SET_NEW_DEAL_OLD_PRICE (state, data) {
    // console.log(data)
    state.newDeal.oldPrice = data
  },
  SET_NEW_DEAL_EXPIRATION_DATE (state, data) {
    // console.log(data)
    state.newDeal.expirationDate = data
  },
  SET_SELECTED_DEAL (state, data) {
    // console.log(data)
    state.selectedDeal = data.deal[0]
    Vue.nextTick(function () {
      // Initializer().owlCarousels()
      // console.log('initializer called from mutation')
    })
  },
  SET_SELECTED_DEAL_LIKED_DISLIKED (state, data) {
    // console.log(data)
    // console.log(state)
    if (data.operationResult === 'DEAL_LIKED') {
      state.selectedDeal.likedBy = [data.userID]
      state.selectedDeal.dislikedBy = []
    } else if (data.operationResult === 'DEAL_DISLIKED') {
      state.selectedDeal.dislikedBy = [data.userID]
      state.selectedDeal.likedBy = []
    }
    Vue.nextTick(function () {
      // Initializer().owlCarousels()
      // console.log('initializer called from mutation')
    })
  },
  ADD_REMOVE_FAVORITES (state, data) {
    // console.log(data)
    // console.log(state)
    if (data.operationResult === 'DEAL_LIKED') {
      state.selectedDeal.likedBy = [data.userID]
      state.selectedDeal.dislikedBy = []
    } else if (data.operationResult === 'DEAL_DISLIKED') {
      state.selectedDeal.dislikedBy = [data.userID]
      state.selectedDeal.likedBy = []
    }
    Vue.nextTick(function () {
      // Initializer().owlCarousels()
      // console.log('initializer called from mutation')
    })
  }
}
