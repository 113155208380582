import Vue from 'vue'
import Vuex from 'vuex'
import user from './user/user.module'
import product from './product/product.module'
import deals from './products/deals.module'
import deal from './deal/deal.module'
import categories from './categories/categories.module'
import filters from './filters/filters.module'
import stores from './stores/stores.module'
import global from './global/global.module'

Vue.use(Vuex)

const getCookie = name => {
  const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  // console.log('cookies')
  // console.log(cookies ? cookies[1] : '')
  return cookies ? cookies[1] : ''
}

export default new Vuex.Store({

  modules: {
    User: user,
    Product: product,
    Deals: deals,
    Categories: categories,
    Global: global,
    Deal: deal,
    Stores: stores,
    Filters: filters
  },
  state: {
    locale: 'en',
    isUserAuthenticated: !!(getCookie('user'))
  },
  mutations: {
    SET_IS_USER_AUTHENTICATED (state, isUserAuthenticated) {
      state.isUserAuthenticated = isUserAuthenticated
    }
  },
  actions: {

  }

})
