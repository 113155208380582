<template>
  <div>
    <b-table :fields='tableFields' striped hover :items='formattedSliders'>
        <template #cell(actions)="row">
            <a size="sm" href="#" @click.prevent="toggleSliderStatus(row)" class="mr-2">
                <font-awesome-icon :icon="row.item.status == 'ACTIVE'? ['far','stop-circle']:['far','play-circle']" />
            </a>
            <a size="sm" href="#" @click.prevent="editSlider(row)" class="mr-2">
                <font-awesome-icon :icon="['fas','edit']" />
            </a>
        </template>
    </b-table>
    <b-row class="justify-content-md-center">
        <b-pagination
        v-model="currentPage"
        :total-rows="numberOfSliders"
        :per-page="slidersPerPage"
        aria-controls="my-table"
        ></b-pagination>
    </b-row>
  </div>
</template>

<script>
import Api from '@/services/Api'
export default {
  name: 'SlidersAdmin',
  props: {
    msg: String
  },
  data: function () {
    return {
      numberOfSliders: 0,
      currentPage: 1,
      slidersPerPage: 8,
      slidersData: [],
      formattedSliders: [],
      tableFields: ['name', 'tag', 'status', 'actions']
    }
  },
  watch: {

    slidersData: {
      deep: true,
      handler () {
        this.formattedSliders = this.slidersData.map(function (item) {
          return {
            name: item.name,
            tag: item.tag,
            status: item.status
          }
        })
      }

    }
  },
  mounted: function () {
    this.getSliders()
  },
  methods: {
    getSliders: function () {
      Api().get('/sliders/sliders', { params: { start: (this.currentPage - 1) * this.slidersPerPage, limit: this.slidersPerPage } }).then((data) => {
        // console.log(data.data.result)
        this.slidersData = data.data.result
        this.numberOfSliders = data.data.count
      })
        .catch(error => {
          this.$toasted.show(error.message,
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        })
    },
    toggleSliderStatus: function (params) {
    },
    editSlider: function (item) {
      // console.log(item)
      var slider = this.slidersData[item.index]
      // console.log(slider)
      this.$router.push({ name: 'sliderAdmin', params: { id: slider.id } })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
