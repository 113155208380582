<template>
  <main class="main">
    <nav aria-label="breadcrumb" class="breadcrumb-nav">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/"><font-awesome-icon :icon="['fas', 'home']" class="mr-2"/></a></li>
          <li class="breadcrumb-item"><a :href=" '/deals/' + (this.selectedDeal.mainCategory?this.selectedDeal.mainCategory.id:'_') +'/_/_'">{{this.selectedDeal.mainCategory?this.selectedDeal.mainCategory.title:'All Deals'}}</a></li>
        </ol>
      </div><!-- End .container -->
    </nav>
    <div v-if="this.loading" class="loading-overlay"><div class="bounce-loader"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
    <div v-if="!this.loading" class="container">
      <div class="row">
        <div class="col-lg-9">
          <div class="product-single-container product-single-default">
            <div class="row">
              <div class="col-lg-4 col-md-4 product-single-gallery">
                <div class="product-slider-container product-item">
                  <div class="">
                    <!--<div class="product-item" :id="image.id" v-for="image in selectedDeal.images" :key="image.id">
                      <img class="product-single-image" v-bind:src="'data:image/jpeg;base64, '+image.data" v-bind:data-zoom-image="'data:image/jpeg;base64, '+image.data"/>
                    </div>-->
                    <!-- <carousel v-bind:per-page="1" :adjustableHeight="false" :paginationEnabled="false" :value="navigateTo">
                      <slide  :id="image.id+'mini'"  v-for="image in selectedDeal.images" :key="image.id+'mini'">
                        <img v-bind:src="'https://s3.amazonaws.com/images.flashsale.com/'+image.fd"/>
                      </slide>
                    </carousel> -->
                    <b-carousel
                      id="carousel-1"
                      v-model="navigateTo"
                      :interval="0"
                      img-width="480"
                      img-height="270"

                      style="text-shadow: 1px 1px 2px #333;"
                      >
                      <b-carousel-slide v-for="(image) in selectedDeal.images" :key="image.id+'slide'"
                          v-bind:img-src="'https://s3.amazonaws.com/images.flashsale.com/'+image.fd"
                        ></b-carousel-slide>
                    </b-carousel>
                  </div>
                  <b-row style="cursor: pointer;" class="prod-thumbnail row owl-dots" >
                    <b-col v-bind:class="index === navigateTo?'active':'unselected'" class="p-1" v-on:click.prevent="navigateTo = index" :id="image.id+'mini'" v-for="(image,index) in selectedDeal.images" :key="image.id+'mini'">
                      <b-img thumbnail v-bind:src="'https://s3.amazonaws.com/images.flashsale.com/'+image.fd"/>
                    </b-col>
                  </b-row>
                </div>
                <!-- <div style="display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin-right: -15px;
                margin-left: -15px;" class="prod-thumbnail row owl-dots">

                    <div style="cursor: pointer" class="col-3 owl-dot" v-bind:class="index === navigateTo?'active':'unselected'" v-on:click.prevent="navigateTo = index" :id="image.id+'mini'" v-for="(image,index) in selectedDeal.images" :key="image.id+'mini'">
                      <div class="thumbnail-container"><img v-bind:src="'https://s3.amazonaws.com/images.flashsale.com/'+image.fd"/></div>
                    </div>
                </div> -->
              </div>

              <div class="col-lg-8 col-md-8">
                <div class="product-single-details">
                  <b-row>
                    <b-col><h3 class="product-title mb-3">{{this.selectedDeal.title}}</h3></b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col class="price-box">
                        <span class="old-price">${{this.selectedDeal.oldPrice}}</span>
                        <span class="product-price">${{this.selectedDeal.newPrice}}</span>
                    </b-col>
                  </b-row>
                  <b-row style="color: grey;" class="deal-stats">
                    <b-col class="stat-col" md="3" sm="3" cols="4">
                      <b-badge class="pr-1">{{this.selectedDeal.score}}</b-badge>
                      <span > Score</span>
                    </b-col>
                    <b-col class="stat-col" md="3" sm="3" cols="4"><i class="pr-1"><font-awesome-icon :icon="['fas', 'eye']"/></i> {{this.selectedDeal.numberOfViews}}{{ this.selectedDeal.numberOfViews === 1?' View':' Views'}}</b-col>
                    <!-- <b-col class="stat-col" md="3" sm="3"><i class="pr-1"><font-awesome-icon :icon="['fas', 'thumbs-up']"/></i> {{this.selectedDeal.numOfLikes}}{{ this.selectedDeal.numberOfLikes === 1?' Like':' Likes'}}</b-col> -->
                    <b-col class="stat-col p-0" sm="3" md="3" cols="4"><i class="pr-1"><font-awesome-icon :icon="['fas', 'comments']"/></i> {{this.selectedDeal.numberOfComments}} Comments</b-col>
                  </b-row>
                  <b-row class="my-3">
                    <b-col md="4" sm="12" class="px-1 mb-2">
                      <b-button class="w-100 show-deal-btn" v-if="this.selectedDeal.urls" target="_blank" v-bind:href="this.selectedDeal.urls[0].indexOf('http') < 0?'https://'+this.selectedDeal.urls[0]:this.selectedDeal.urls[0]">Show Deal</b-button>
                    </b-col>
                    <b-col md="4" sm="6" class="px-1 mb-2">
                      <b-button :variant="this.selectedDeal.status!== 'ACTIVE'?'success':'danger'" class="w-100" v-if="this.user.loggedInUser.role === 'SUPER_ADMIN'" @click.prevent="activateDeactivateDeal">{{this.selectedDeal.status!== 'ACTIVE'?'ACTIVATE':'DEACTIVATE'}}</b-button>
                    </b-col>
                    <b-col md="2" sm="6" class="px-1 mb-2">
                      <b-button variant="info" class="w-100" v-if="this.user.loggedInUser.role === 'SUPER_ADMIN'" :href="'/edit-deal/'+this.selectedDeal.id">EDIT</b-button>
                    </b-col>
                  </b-row>
                  <b-row class="mx-auto mb-2">
                    <b-col md="4" sm="6" class="p-0  my-2 stat-col">Latest Update : {{new Date(this.selectedDeal.updatedAt).toLocaleDateString("en-US",{year: 'numeric', month:'short', day: 'numeric'})}}</b-col>
                    <b-button href="#" class="paction ml-1" pill variant="outline-none" title="Dislike" @click.prevent="likeDislikeDeal(1)">
                            <font-awesome-icon :icon="likedIcon" /> Like
                    </b-button>

                    <b-button href="#" pill variant="outline-none" class="mx-1 paction" title="Dislike" @click.prevent="likeDislikeDeal(0)">
                            <font-awesome-icon :icon="dislikedIcon" /> Dislike
                    </b-button>
                    <b-col class="px-1 stat-col my-2" cols="5" md="3" sm="3">
                      <a href="#" @click.prevent="reportDeal">Report as expired</a>
                    </b-col>
                  </b-row>
                </div><!-- End .product-single-details -->
              </div><!-- End .col-lg-5 -->
            </div><!-- End .row -->
          </div><!-- End .product-single-container -->

          <b-card class="product-desc-content" no-body>
            <b-card-body v-html="'<h5>Description:</h5>'+this.selectedDeal.description" ></b-card-body>
          </b-card><!-- End .product-desc-content -->
          <b-card no-body class="my-3" v-if="this.selectedDeal.editor !== undefined">
            <b-card-header><h5 class="mb-1">Posted By:</h5></b-card-header>
            <b-card-text>
              <div class="container m-2 py-4">
                <b-row>
                  <b-col md="1" sm="2" cols="3" style="margin: auto;"><b-avatar variant="info" :src="this.selectedDeal.editor.profilePicture" class="mr-4"></b-avatar></b-col>
                  <b-col col="6">
                    <b-row><span class="mr-auto" style="color:#03658c"><a :href="'/profile/'+this.selectedDeal.editor.id"><b>{{this.selectedDeal.editor.displayName}}</b></a></span></b-row>
                    <b-row style="color:grey">
                      <b-col sm="12" md="2" class="px-0">
                        <span class="mr-2"><font-awesome-icon :icon="['fas', 'award']"/></span>
                        {{this.selectedDeal.editor.badges.length>0 ? this.selectedDeal.editor.badges[0].label:' New User'}}
                      </b-col>
                      <b-col class="mr-0 px-0">
                        Joined: {{new Date(this.selectedDeal.editor.joinedAt).toLocaleDateString("en-US",{year: 'numeric', month:'long'})}}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col sm="12" md="3"><font-awesome-icon :icon="['fas', 'medal']"/>  {{this.selectedDeal.editor.stats.reputation}} Reputation Points</b-col>
                  <b-col sm="12" md="3"><font-awesome-icon :icon="['fas', 'tags']"/> {{this.selectedDeal.editor.stats.numberOfDeals}} Deals Posted</b-col>
                  <b-col sm="12" md="3"><font-awesome-icon :icon="['fas', 'comments']"/> {{this.selectedDeal.editor.stats.numberOfComments}} Comments Posted</b-col>
                  <b-col sm="12" md="3"><font-awesome-icon :icon="['fas', 'thumbs-up']"/> {{this.selectedDeal.editor.stats.numberOfReviews}} Votes Submitted</b-col>
                </b-row>
              </div>
            </b-card-text>
          </b-card>
          <b-card no-body>
            <b-card-header><h4 class="m-0">Comments:</h4></b-card-header>
            <b-card-text>
              <div class="container py-4">
                <b-row>
                  <comments-list :dealID="this.dealID"></comments-list>
                </b-row>
              </div>
            </b-card-text>
          </b-card>
        </div><!-- End .col-lg-9 -->

        <div class="sidebar-overlay"></div>
        <div class="sidebar-toggle"><i class="icon-sliders"></i></div>
        <aside class="sidebar-product col-lg-3 padding-left-lg">
          <div class="">
            <div class="widget widget-banner">
              <div class="banner banner-image">
                <a href="#">
                  <img src="/assets/images/banners/banner-sidebar.jpg" alt="Banner Desc">
                </a>
              </div><!-- End .banner -->
            </div><!-- End .widget -->
          </div>
        </aside><!-- End .col-md-3 -->
      </div><!-- End .row -->
    </div><!-- End .container -->
    <div class="container"> </div>
  </main>
</template>

<script>
// import the component
import FeaturedDealsColumn from '@/components/deal/FeaturedDealsColumn.vue'
import { mapMutations, mapActions } from 'vuex'
import CommentsList from '../comment/CommentsList.vue'
import Api from '@/services/Api'
/* const getCookie = name => {
  const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  return cookies ? cookies[1] : ''
} */

export default {
  components: {
    // eslint-disable-next-line no-undef
    // eslint-disable-next-line vue/no-unused-components
    FeaturedDealsColumn,
    CommentsList },
  name: 'ShowDeal',
  validations: {

  },
  data () {
    return {
      loading: false,
      navigateTo: 0,
      dealID: '',
      comments: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getDeal(this.$route.params.id).then(function (data) {
        // console.log(data)
        document.title = data.deal[0].title
      })
      this.dealID = this.$route.params.id
    })
  },
  computed: {
    selectedDeal: {
      get () {
        // console.log('get selectedDeal called')
        return this.$store.state.Deal.selectedDeal
      }
    },
    totalReviews: function () {
      return this.selectedDeal.numOfDislikes + this.selectedDeal.numOfLikes
    },
    likedIcon: function () {
      var isLikedIcon = ['far', 'thumbs-up']

      if (this.selectedDeal.likedBy.length > 0) {
        isLikedIcon = ['fas', 'thumbs-up']
      }

      return isLikedIcon
    },
    dislikedIcon: function () {
      var dislikedIcon = ['far', 'thumbs-down']
      if (this.selectedDeal.dislikedBy.length > 0) {
        dislikedIcon = ['fas', 'thumbs-down']
      }
      return dislikedIcon
    },
    ratingStyle: function () {
      var percentage = 0

      if (this.selectedDeal.numOfDislikes !== 0 || this.selectedDeal.numOfLikes !== 0) { percentage = 100 * this.selectedDeal.numOfLikes / (this.selectedDeal.numOfDislikes + this.selectedDeal.numOfLikes) }
      // console.log('percentage:' + percentage)
      return 'width:' + percentage + '%'
    },
    shouldShowAuth: {
      get () {
        return this.$store.state.Global.shouldShowAuth
      },
      set (visibility) {
        this.$store.commit('SET_AUTH_DIALOG_VISIBILITY', visibility)
      }
    },
    user: {
      get () {
        return this.$store.state.User.user
      }
    }
  },
  methods: {
    ...mapMutations({

    }),
    ...mapActions({
      getDeal: 'getDeal',
      likeDislikeDealAction: 'likeDislikeDeal',
      reportDealAction: 'reportDeal'
    }),
    likeDislikeDeal (data) {
      if (Object.keys((this.user.loggedInUser)).length === 0) {
        this.shouldShowAuth = true
        return
      }

      this.likeDislikeDealAction({ liked: data, dealID: this.selectedDeal.id })
    },
    reportDeal (data) {
      if (Object.keys((this.user.loggedInUser)).length === 0) {
        this.shouldShowAuth = true
        return
      }

      this.reportDealAction({ reportType: 'EXPIRED', dealID: this.selectedDeal.id, userID: this.user.id }).then((data) => {
        this.$toasted.show('Thank you for notifying us about this expired deal, we will take action soon.',
          { onComplete: function () {
            // console.log('toast completed')
          } }).goAway(4000)
      })
    },
    setNavigateTo: function (item) {
      this.navigateTo = item
    },
    activateDeactivateDeal: function (status) {
      Api().post('deals/activate_deactivate_deal', { id: this.selectedDeal.id, submitter: this.user.loggedInUser.id, status: this.selectedDeal.status === 'DELETED' ? 1 : 0 }).then(response => {
        if (response.status === 200) {
          this.getDeal(this.$route.params.id)
          this.dealID = this.$route.params.id
          this.$toasted.show('Deal ' + (this.selectedDeal.status === 'ACTIVE' ? 'deactivated' : 'activated'),
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        }
      })
        .catch(error => {
          this.$toasted.show(error.message,
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        })
    }

  },
  mixins: []
}
</script>

<style scoped>

</style>
