<template>
  <div class="home">

  <main class="main">
    <div class="container">
      <Slider></Slider>
      <hr>
      <h5 class="text-left mt-5 mb-2">Editors' Choice</h5>
      <EditorsChoiceDeals></EditorsChoiceDeals>
      <hr>
      <h5 class="text-left mt-5 mb-2">Featured Products</h5>
      <FeaturedDeals></FeaturedDeals>

      <div class="mb-3"></div><!-- margin -->

      <div class="mb-6"></div><!-- margin -->
    </div><!-- End .container -->
  </main>

  </div>
</template>

<script>
// @ is an alias to /src
import HomeMixin from '@/components/home/Home.mixin'
import FeaturedDeals from '../components/home/FeaturedDeals'
import EditorsChoiceDeals from '../components/home/EditorsChoiceDeals'
import Slider from '../components/home/Slider'
import { mapActions } from 'vuex'
export default {
  name: 'home',
  components: {
    FeaturedDeals,
    EditorsChoiceDeals,
    Slider
  },
  mounted: function () {
    if (this.routeName === 'verify_email') { this.verifyEmail() }
  },
  computed: {
    user: {
      get () {
        return this.$store.state.User.user
      }
    }
  },
  mixins: [HomeMixin],
  methods: {
    ...mapActions(['setHeaderVisibility'])
  }

}
</script>
