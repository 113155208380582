import initializer from '@/services/Initializers'

export default {
  SET_DEALS (state, deals) {
    state.deals = deals
  },
  SET_FEATURED_DEALS (state, data) {
    state.featuredDeals = data.result
    state.numberOfFeaturedDeals = data.count
  },
  SET_WIDGET_FEATURED_DEALS (state, data) {
    state.widgetFeaturedDeals = initializer().chunkArray(data.result, 3)
  },
  SET_EDITORS_CHOICE_DEALS (state, data) {
    state.editorsChoiceDeals = data.result
    state.numberOfEditorsChoiceDeals = data.count
  },
  SET_FILTERED_DEALS (state, data) {
    state.filteredDeals = { deals: data.deals, totalCount: data.numberOfFilteredDeals }
  }

}
