export default () => {
  var initializer = {
    chunkArray: function (array, size) {
      var result = []

      if (array.length > size) {
        var subarray = array.slice(0, size)
        var offset = array.length
        var subarray2 = array.slice(subarray.length,
          offset)

        result.push(subarray.slice(0, size))
        if (subarray2.length > size) {
          // eslint-disable-next-line no-undef
          result.push(...chunkArray(subarray2, size))
        } else {
          result.push(subarray2)
        }
      } else {
        result.push(array)
      }
      // console.log(result)
      return result
    }

  }
  return initializer
}
