<template>
  <div  class="admin-root content">
    <div v-if="this.isAdmin">
      <div class="container">
          <b-button class="my-4" v-b-toggle.sidebar-backdrop><font-awesome-icon :icon="['fas','bars']" class="mr-3"/>Menu</b-button>
              <router-view></router-view>
      </div>

      <b-sidebar
        id="sidebar-backdrop"
        title="Flashsale Admin"
        :backdrop-variant="variant"
        backdrop
        shadow
      >
        <b-list-group>
          <b-list-group-item href="#foobar">Dashboard</b-list-group-item>
          <b-list-group-item href="/admin/users" :active="this.currentRoute === 'usersAdmin'">Users</b-list-group-item>
          <b-list-group-item href="#">Deals</b-list-group-item>
          <b-list-group-item href="/admin/collections" :active="this.currentRoute === 'collectionsAdmin' || this.currentRoute === 'collectionAdmin'">Collections</b-list-group-item>
          <b-list-group-item href="#foobar">Categories</b-list-group-item>
          <b-list-group-item href="#foobar">Stores</b-list-group-item>
          <b-list-group-item href="/admin/sliders" :active="this.currentRoute === 'slidersAdmin' || this.currentRoute === 'sliderAdmin'">Sliders</b-list-group-item>
          </b-list-group>
      </b-sidebar>
    </div>
    <div class="container" v-if="!this.isAdmin"> <b style="color:red">Loading ... </b></div>

  </div>
</template>

<script>
export default {
  name: 'AdminRoot',
  props: {
    msg: String
  },
  computed: {
    user: {
      get () {
        return this.$store.state.User.user.loggedInUser
      }
    },
    isAdmin: function () {
      if (this.user.role === 'SUPER_ADMIN' || this.user.role === 'ADMIN') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    user: function (newValue, oldValue) {
      if (newValue.role) {
        if (newValue.role !== 'SUPER_ADMIN' && newValue.role !== 'ADMIN') {
          window.location = '/'
        }
      }
    }
  },
  mounted: function () {
    this.currentRoute = this.$route.name
  },
  data: function () {
    return {
      variant: 'dark',
      currentRoute: 'admin',
      variants: [
        'transparent',
        'white',
        'light',
        'dark',
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info'
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
