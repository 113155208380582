import Vue from 'vue'
import Api from '@/services/Api'

export default {

  getUser (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get('/api/user/get')
        .then(({ body }) => {
          context.commit('SET_USER', body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },
  getUserStats (context, userID) {
    return new Promise((resolve, reject) => {
      Api()
        .post('/users/get_user_stats', { id: userID })
        .then((body) => {
          // console.log(body.data)
          var parsedData = body.data
          if (typeof body.data.user.badges !== 'object') {
            parsedData.user.badges = JSON.parse(body.data.user.badges)
          }
          resolve(parsedData)
        })
        .catch(error => reject(error))
    })
  },
  getUserDeals (context, data) {
    return new Promise((resolve, reject) => {
      Api()
        .post('/deals/user_deals', { userID: data.userID, start: (data.page - 1) * data.count, limit: data.count })
        .then((body) => {
          // console.log(body.data)
          var parsedData = body.data
          // parsedData.user.badges = JSON.parse(body.data.user.badges)
          resolve(parsedData)
        })
        .catch(error => reject(error))
    })
  },
  getUsers (context, data) {
    return new Promise((resolve, reject) => {
      Api()
        .post('/users/get_users', { start: (data.page - 1) * data.count, limit: data.count })
        .then((body) => {
          // console.log(body.data)
          var parsedData = body.data
          // parsedData.user.badges = JSON.parse(body.data.user.badges)
          resolve(parsedData)
        })
        .catch(error => reject(error))
    })
  },
  activateDeactivateUsers (context, data) {
    return new Promise((resolve, reject) => {
      // console.log(data)
      Api()
        .post('/users/activate_deactivate_user', { status: data.status, userID: data.userID })
        .then((body) => {
          // console.log(body)
          var parsedData = body
          // parsedData.user.badges = JSON.parse(body.data.user.badges)
          resolve(parsedData)
        })
        .catch(error => reject(error))
    })
  },
  signUp2 (context, { name, password }) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post('/api/register/post', { name, password })
        .then(() => {
          context.commit('RESET_USER')
          resolve()
        })
        .catch(error => reject(error))
    })
  },
  signUp (context, { email, password, displayName, captchaToken }) {
    // console.log(displayName)
    return new Promise((resolve, reject) => {
      Api()
        .post('/auth/register', { emailAddress: email, password: password, displayName: displayName, captchaToken: captchaToken })
        .then(({ data }) => {
          context.commit('SET_LOGGED_IN_USER', data)
          context.commit('SET_IS_USER_AUTHENTICATED', true)
          resolve(data)
        })
        .catch((error) => reject(error))
    })
  },
  resendVerificationCode (context, { token }) {
    return new Promise((resolve, reject) => {
      Api()
        .post('/auth/send_verification_token', { token: token })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => reject(error))
    })
  },
  verifyEmail (context, { code, userID }) {
    return new Promise((resolve, reject) => {
      Api()
        .post('/auth/verify_email', { userID: userID, verificationToken: code })
        .then(({ data }) => {
          // console.log(data)
          context.commit('SET_LOGGED_IN_USER', data)
          resolve()
        })
        .catch((error) => reject(error))
    })
  },

  signIn (context, { emailAddress, password, captchaToken }) {
    return new Promise((resolve, reject) => {
      // console.log(emailAddress)
      // console.log(password)
      Api()
        .post('/auth/login', { emailAddress: emailAddress, password: password, captchaToken: captchaToken })
        .then(({ data }) => {
          // console.log(data)
          context.commit('SET_LOGGED_IN_USER', data)
          context.commit('SET_IS_USER_AUTHENTICATED', true)
          context.commit('RESET_USER')
          resolve(data)
        })
        .catch((error) => reject(error))
    })
  },
  requestResetPassword (context, { emailAddress }) {
    return new Promise((resolve, reject) => {
      // console.log(emailAddress)
      Api()
        .post('/auth/request_reset_password', { emailAddress: emailAddress })
        .then(({ data }) => {
          // console.log(data)
          context.commit('SET_IS_USER_AUTHENTICATED', false)
          context.commit('RESET_USER')
          resolve(data)
        })
        .catch((error) => reject(error))
    })
  },
  changePassword (context, { emailAddress, newPassword, verificationToken }) {
    return new Promise((resolve, reject) => {
      // console.log(emailAddress)
      Api()
        .post('/auth/submit_new_password', { emailAddress: emailAddress, verificationToken: verificationToken, newPassword: newPassword })
        .then(({ data }) => {
          // console.log(data)
          resolve(data)
        })
        .catch((error) => reject(error))
    })
  },
  fetchLoggedInUser (context) {
    return new Promise((resolve, reject) => {
      let token = window.localStorage.getItem('x-token')

      if (token !== undefined && token !== 'undefined' && token !== '') {
        Api()
          .post('/auth/get_logged_in_user',
            { token: token })
          .then(({ data }) => {
            context.commit('SET_LOGGED_IN_USER', data)
            context.commit('SET_IS_USER_AUTHENTICATED', true)
            context.commit('RESET_USER')
            resolve(data)
          })
          .catch((error) => reject(error))
      }
    })
  },
  signOut (context) {
    // let vm = Vue
    return new Promise((resolve, reject) => {
      // context.setCookie('user', '', -3600 * 24 * 7)
      // console.log(context)
      window.$cookies.remove('user')
      context.commit('SET_IS_USER_AUTHENTICATED', false)
      context.commit('SET_LOGGED_IN_USER', {})
      resolve(true)
    })
  }
}
