<template>
  <div>
    <h3>Slides List</h3>
    <b-row class="my-3 float-right">
      <b-button v-b-modal.modal-1 variant="outline-primary" @click="createSlide" ><b>+</b> Add Slide</b-button>
    </b-row>
    <b-table :fields='tableFields' striped hover :items='formattedSlides'>
        <template #cell(actions)="row">
            <a size="sm" href="#" @click.prevent="toggleSlideStatus(row)" class="mr-2">
                <font-awesome-icon :icon="row.item.status == 'ACTIVE'? ['far','stop-circle']:['far','play-circle']" />
            </a>
            <a v-b-modal.modal-1 size="sm" href="#" @click.prevent="editSlide(row)" class="mr-2">
                <font-awesome-icon :icon="['fas','edit']" />
            </a>
            <a v-b-modal.delete-slide-modal size="sm" href="#" @click.prevent="showDeleteSlideDialog(row)" class="mr-2">
                <font-awesome-icon :icon="['fas','trash']" />
            </a>
        </template>
        <template #cell(image)="row">
            <img size="sm" style="max-height: 45px;" :src="'https://s3.amazonaws.com/images.flashsale.com/'+row.item.image" @click.prevent="toggleSlideStatus(row)" class="mr-2">
        </template>
    </b-table>
    <b-row class="justify-content-md-center">
        <b-pagination
        v-model="currentPage"
        :total-rows="numberOfSlides"
        :per-page="slidesPerPage"
        aria-controls="my-table"
        ></b-pagination>
    </b-row>
    <b-modal id="modal-1" @ok="submitSlide" :title="!this.isNewSlide?'Edit Slide':'Create Slide'">
      <b-form>
        <b-form-group label-for="title" label="Title :" :class="{ 'form-group--error': $v.editedSlide.title.$error }">
          <b-form-input type="text" placeholder="Title" class="form-input form-wide mb-2" v-model.trim="$v.editedSlide.title.$model" id="slide-title" ></b-form-input>
          <b-form-invalid-feedback :state="(!this.$v.editedSlide.title.$dirty)||(this.$v.editedSlide.title.required)">
            Title field is required
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label-for="subtitle" label="Subtitle :" :class="{ 'form-group--error': $v.editedSlide.title.$error }">
          <b-form-input type="text" placeholder="Subtitle" class="form-input form-wide mb-2" v-model.trim="$v.editedSlide.subtitle.$model" id="slide-subtitle" ></b-form-input>
          <b-form-invalid-feedback :state="(!this.$v.editedSlide.subtitle.$dirty)||(this.$v.editedSlide.subtitle.required)">
            Subtitle field is required
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label-for="body" label="Body :" :class="{ 'form-group--error': $v.editedSlide.body.$error }">
          <b-form-textarea type="text" placeholder="Body" class="form-input form-wide mb-2" v-model.trim="$v.editedSlide.body.$model" id="slide-body" ></b-form-textarea>
          <b-form-invalid-feedback :state="(!this.$v.editedSlide.body.$dirty)||(this.$v.editedSlide.body.required)">
            Body field is required
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label-for="payload" label="Paylaod :" :class="{ 'form-group--error': $v.editedSlide.body.$error }">
          <b-form-textarea type="text" placeholder="Paylaod" class="form-input form-wide mb-2" v-model.trim="$v.editedSlide.payload.$model" id="slide-payload" ></b-form-textarea>
        </b-form-group>
        <b-form-group label-for="url" label="Url :" :class="{ 'form-group--error': $v.editedSlide.title.$error }">
          <b-form-input type="text" placeholder="Url" class="form-input form-wide mb-2" v-model.trim="$v.editedSlide.url.$model" id="slide-url" ></b-form-input>
          <b-form-invalid-feedback :state="(!this.$v.editedSlide.url.$dirty)||(this.$v.editedSlide.url.required)">
            Url field is required
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label-for="order" label="Order :" :class="{ 'form-group--error': $v.editedSlide.order.$error }">
          <b-form-input type="number" placeholder="Order" class="form-input form-wide mb-2" v-model.trim="$v.editedSlide.order.$model" id="slide-url" ></b-form-input>
          <b-form-invalid-feedback :state="(!this.$v.editedSlide.order.$dirty)||(this.$v.editedSlide.order.required)">
            Order field is required
          </b-form-invalid-feedback>
        </b-form-group>
        <b-row>
          <b-col sm="3" md="3" ><img v-if="editedSlide.fd" class="w-100 mb-3" :src="'https://s3.amazonaws.com/images.flashsale.com/'+editedSlide.fd"></b-col>
          <b-col class="m-auto">{{editedSlide.image}}</b-col>
        </b-row>
        <b-list-group-item v-if="uploadingImage">Uploading</b-list-group-item>
        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-sending="sendingEvent" v-on:vdropzone-success="fileUploaded" v-on:vdropzone-thumbnail="thumbnailEvent"></vue-dropzone>
      </b-form>
    </b-modal>
    <b-modal id="delete-slide-modal" @ok="deleteSlide" title="Delete Slide ?">
      <p class="my-4">Are you sure to delete this slide ?</p>
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Api from '@/services/Api'
import imageTools from '@/services/ImageTools'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
  name: 'SliderAdmin',
  props: {
    msg: String
  },
  components: { vueDropzone: vue2Dropzone },
  data: function () {
    return {
      numberOfSlides: 0,
      currentPage: 1,
      slidesPerPage: 8,
      slidesData: [],
      sliderID: '',
      formattedSlides: [],
      uploadingImage: false,
      isNewSlide: false,
      operation: 'CREATE',
      editedSlide: {
        title: '',
        subtitle: '',
        url: '',
        body: '',
        order: '',
        image: '',
        payload: '{}'
      },
      tableFields: ['title', 'image', 'status', 'order', 'actions'],
      dropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + 'images/save_image_raw',
        thumbnailWidth: 300,
        maxFilesize: 1,
        addRemoveLinks: true,
        dictRemoveFile: 'Remove Image',
        resizeWidth: 500,
        headers: { 'x-user': this.$cookies.get('user') },
        renameFile: function (file) {
          return new Date().getTime()
        },
        previewsContainer: false
        // previewTemplate: this.dzPreviewTemplate()
      }
    }
  },
  validations: {
    editedSlide: {
      title: { required },
      subtitle: { required },
      url: { required },
      body: { required },
      order: { required },
      image: { required },
      payload: {}
    }
  },
  watch: {
    currentPage: function (page) {
      this.getSlides(this.sliderID)
    },
    slidesData: {
      deep: true,
      handler () {
        this.formattedSlides = this.slidesData.map(function (item) {
          return {
            image: item.fd,
            title: item.title,
            status: item.status,
            order: item.order
          }
        })
      }

    }
  },
  mounted: function () {
    this.sliderID = this.$route.params.id
    this.getSlides(this.sliderID)
  },
  methods: {
    getSlides: function (sliderID) {
      Api().get('/sliders/slides', { params: { id: sliderID, start: (this.currentPage - 1) * this.slidesPerPage, limit: this.slidesPerPage } }).then((data) => {
        // console.log(data.data.result)
        this.slidesData = data.data.result
        this.numberOfSlides = data.data.count
      })
        .catch(error => {
          this.$toasted.show(error.message,
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        })
    },
    toggleSliderStatus: function (params) {
    },
    createSlide: function (item) {
      this.$v.$reset()
      // console.log(item)
      this.editedSlide = {
        title: '',
        subtitle: '',
        url: '',
        body: '',
        order: this.numberOfSlides + 1,
        slider: this.sliderID,
        editor: this.$cookies.get('user')
      }
      this.isNewSlide = true
      this.operation = 'CREATE'
      // show edit slide dialog
    },
    editSlide: function (item) {
      // console.log(item)
      var slide = this.slidesData[item.index]
      // console.log(slide)
      this.editedSlide = slide
      this.isNewSlide = false
      this.operation = 'UPDATE'
      // show edit slide dialog
    },
    showDeleteSlideDialog: function (item) {
      // console.log(item)
      var slide = this.slidesData[item.index]
      // console.log(slide)
      this.editedSlide = slide
      this.isNewSlide = false
      // show edit slide dialog
    },
    submitSlide: function (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.editedSlide.operation = this.operation === 'CREATE' ? 1 : this.operation === 'UPDATE' ? 2 : 0
        this.editedSlide.slide = this.editedSlide.id
        var self = this
        // console.log(this.editedSlide)
        Api().get('/sliders/add_edit_remove_slide', { params: self.editedSlide }).then((data) => {
          this.getSlides(self.sliderID)
          this.$nextTick(() => {
            this.$bvModal.hide('modal-1')
          })
        })
          .catch(error => {
            this.$toasted.show(error.message,
              { onComplete: function () {
                // console.log('toast completed')
              } }).goAway(4000)
          })
      } else {
        // console.log(this.$v.$invalid)
        return false
      }
    },
    deleteSlide: function (slideID) {
      var self = this
      Api().get('/sliders/add_edit_remove_slide', { params: { slide: this.editedSlide.id, slider: this.editedSlide.slider, operation: 0 } }).then((data) => {
        this.getSlides(self.sliderID)
      })
        .catch(error => {
          this.$toasted.show(error.message,
            { onComplete: function () {
            // console.log('toast completed')
            } }).goAway(4000)
        })
    },
    sendingEvent (file, xhr, formData) {
      this.uploadingImage = true
      formData.append('submittedBy', this.$cookies.get('user'))
      formData.append('thumbnailData', file.dataURL)
      formData.append('type', 'SLIDE')
      formData.append('name', file.upload.filename)
      formData.append('id', file.upload.uuid)

      // console.log(file)
    },
    fileUploaded (file, xhr, formData) {
      this.uploadingImage = false
      this.editedSlide.image = file.upload.uuid
      this.editedSlide.fd = xhr.fd
      // console.log(xhr)
    },
    thumbnailEvent (file, dataUrl) {
      var vm = this
      imageTools().thumbnailify(file.dataURL, 200).then((base64) => {
        vm.thumbnailHeap = base64
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
