<template>
  <div class="col-md-12">
    <b-form action="#" >

      <b-form-group label="Email address" label-for="login-email" id="email-input-group-1" :class="{ 'form-group--error': $v.email.$error }">
        <b-form-input type="email" placeholder="Enter email" class="form-input form-wide mb-2" v-model.trim="$v.email.$model" id="login-email" ></b-form-input>
        <b-form-invalid-feedback class="error" :state="($v.email.email || (!this.$v.email.$dirty))">invalid email address</b-form-invalid-feedback>
        <b-form-invalid-feedback :state="(!this.$v.email.$dirty)||(this.$v.email.required)">
          Email field is required
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Password" label-for="login-password"  :class="{ 'form-group--error': $v.password.$error }">
        <!--<tree-view :data="$v.email" :options="{rootObjectKey: '$v.email', maxDepth: 2}"></tree-view>-->
        <b-form-input type="password" placeholder="Enter password" class="form-input form-wide mb-2" v-model.trim="$v.password.$model" id="login-password" > </b-form-input>
        <b-form-invalid-feedback :state="($v.password.required || !$v.password.$dirty)">
          Password is required
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="($v.password.minLength || !$v.password.$dirty)">
          Password should be at least {{$v.password.$params.minLength.min}} characters long.
        </b-form-invalid-feedback>
        <div :key="index" v-for="(error, index) in errors"><b-form-invalid-feedback :state="false">{{error.message}}</b-form-invalid-feedback></div>
      </b-form-group>
      <footer class="modal-footer">
        <div class="w-100">
          <b-button variant="link" href="#" class="float-left" @click.prevent="$emit('authPageChanged','RECOVER_PASSWORD')"> Forgot your password?</b-button>
          <b-button
            variant="primary"
            class="float-right" @click.prevent="signIn">LOGIN</b-button>
        </div>
        <b-row class="text-center w-100" >
          <b-col>Don't have an account ? <a @click.prevent="$emit('authPageChanged','REGISTER')" href="#" class=""> Register</a></b-col>
        </b-row>
      </footer>

    </b-form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import LoginMixin from './Login.mixin'

export default {
  name: 'Login',
  mixins: [validationMixin, LoginMixin]
}
</script>

<style scoped>

</style>
