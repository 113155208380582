export default {
  user: {
    id: '',
    email: '',
    password: '',
    displayName: '',
    emailVerificationCode: '',
    loggedInUser: {}
  }
}
