export default {
  SET_STORE_FILTER (state, data) {
    // console.log(data)
    state.storeFilter = data
  },
  SET_CATEGORY_FILTER (state, data) {
    // console.log(data)
    state.categoryFilter = data
  },
  SET_KEYWORD_FILTER (state, data) {
    // console.log(data)
    state.keywordFilter = data
  },
  REMOVE_STORE_FILTER (state, data) {
    // console.log(data)
    state.storeFilter = {}
  },
  REMOVE_CATEGORY_FILTER (state, data) {
    // console.log(data)
    state.categoryFilter = {}
  },
  REMOVE_KEYWORD_FILTER (state, data) {
    // console.log(data)
    state.keywordFilter = ''
  },
  SET_GLOBAL_FILTER (state, data) {
    // console.log(data)
    state.globalFilter = data
  }

}
