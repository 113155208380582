
import Api from '@/services/Api'
import Vue from 'vue'

const getCookie = name => {
  const cookies = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  return cookies ? cookies[1] : ''
}

export default {
  deleteFile (context, data) {
    return new Promise((resolve, reject) => {
      Api().post('images/delete_image', { id: data.id }).then(response => {
        if (response.status === 200) {
          // console.log('REMOVE_NEW_DEAL_IMAGE:' + data)
          context.commit('REMOVE_NEW_DEAL_IMAGE', data)
          resolve(data)
        }
      })
        .catch(error => {
          // console.log(error)
          reject(error)
        })
    })
  },
  addFile (context, data) {
    // console.log('ADD_NEW_DEAL_IMAGE:' + data)
    context.commit('ADD_NEW_DEAL_IMAGE', data)
  },
  addImage (context, data) {

  },
  submitDeal (context, data) {
    return new Promise((resolve, reject) => {
      Api().post('deals/create_or_edit_deal', { id: data.id,
        title: data.title,
        stores: data.stores,
        images: data.images,
        captchaToken: data.captchaToken,
        categories: data.categories,
        newPrice: data.newPrice,
        oldPrice: data.oldPrice,
        description: data.description,
        editor: getCookie('user'),
        urls: [data.url],
        ...(data.id !== undefined && data.id !== '') && { id: data.id },
        ...(data.expirationDate !== undefined && data.expirationDate !== '') && { expiresAt: Date.parse(data.expirationDate) },
        mainImage: data.mainImage
      }).then(response => {
        if (response.status === 200) {
          resolve(response.data)
        }
      })
        .catch(error => {
          reject(error)
          // console.log(error)
        })
    })
  },
  getDeal (context, data) {
    return new Promise((resolve, reject) => {
      Api().post('deals/deal', { id: data, userID: getCookie('user') }).then(response => {
        if (response.status === 200) {
          // console.log(response.data)
          context.commit('SET_SELECTED_DEAL', response.data)
          resolve(response.data)
          Vue.nextTick(function () {
            // Initializers().owlCarousels()
            // console.log('initializer called from action')
          })
        }
      })
        .catch(error => {
          reject(error)
          // console.log(error)
        })
    })
  },
  likeDislikeDeal ({ commit, state }, data) {
    // console.log(data)
    return new Promise((resolve, reject) => {
      Api().post('deals/like_dislike_deal', { liked: data.liked, dealID: data.dealID, userID: getCookie('user') }).then(response => {
        if (response.status === 200) {
          // console.log(response.data)
          if (response.data.dealID) { commit('SET_SELECTED_DEAL_LIKED_DISLIKED', response.data) }
        }
      })
        .catch(error => {
          reject(error)
          // console.log(error)
        })
    })
  },
  reportDeal ({ commit, state }, data) {
    // console.log(data)
    return new Promise((resolve, reject) => {
      Api().post('deals/report_deal', { reportType: data.reportType, dealID: data.dealID, userID: getCookie('user') }).then(response => {
        if (response.status === 200) {
          // console.log(response.data)
          // if (response.data.dealID) { commit('SET_DEAL_REPORTED', response.data) }
          resolve(response.data)
        }
      })
        .catch(error => {
          reject(error)
          // console.log(error)
        })
    })
  }

}
