import axios from 'axios'

export default () => {
  var API_BASE_URL = process.env.VUE_APP_API_ENDPOINT
  // var API_BASE_URL = `http://127.0.0.1:1337/api/v1/`
  // var API_BASE_URL = `https://api.ensolde.com/api/v1/`
  // var API_BASE_URL = `http://172.27.0.101:1337/api/v1/`
  var axiosApi = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: false,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  axiosApi.interceptors.request.use(async config => {
    const xToken = window.localStorage.getItem('x-token')
    /* // console.log('X-Token = ' + xToken) */
    config.headers['X-Token'] = (xToken)
    return config
  })
  return axiosApi
}
