import { required } from 'vuelidate/lib/validators'
export default {
  computed: {
    user: {
      get () {
        return this.$store.state.User.user
      }
    },

    verificationCode: {
      get () {
        return this.$store.state.User.user.emailVerificationCode
      },

      set (emailVerificationCode) {
        this.$store.commit('SET_EMAIL_VERIFICATION_CODE', emailVerificationCode)
      }
    }

  },
  validations: {
    verificationCode: {
      required
    }
  },
  data: function () {
    return {
      errors: [],
      isLoading: false

    }
  },

  methods: {
    async verifyEmail () {
      this.errors.length = 0
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isLoading = true
        await this.$store.dispatch('verifyEmail', { userID: this.user.loggedInUser.id,
          code: this.user.emailVerificationCode }).then((data) => {
          this.$toasted.show('Your email is now verified, enjoy your time on FlashSale !!',
            { onComplete: function () {
              // console.log('toast completed')
            } }).goAway(1500)
          this.$emit('closeDialog')
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          // console.log(error)
          if (error.response && error.response.status === 400) {
            this.errors.push({ message: 'Invalid Verification Code', id: error.response.status })
            // console.log(this.errors)
          }
        })
      }
    },
    async resendCode () {
      this.errors.length = 0
      this.isLoading = true
      let authToken = window.localStorage.getItem('x-token')
      await this.$store.dispatch('resendVerificationCode', { token: authToken }).then((data) => {
        this.$toasted.show('A verification code has been sent to your email',
          { onComplete: function () {
            // console.log('toast completed')
          } }).goAway(1500)
        this.$emit('emailVerified')
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        // console.log(error)
        if (error.response && error.response.status === 400) {
          this.errors.push({ message: 'Invalid Verification Code', id: error.response.status })
          // console.log(this.errors)
        } else {
          this.errors.push({ message: 'an error has occurred', id: 999 })
        }
      })
    }
  }
}
