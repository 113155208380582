<template>
  <div class="col-md-12">
    <form action="#" class="mb-1">
      <div v-if="emailSent"> An email has been sent with recovery instructions if there is an account associated with this email. </div>
      <b-form-group label="Email address" label-for="login-email"  v-if="!emailSent" class="form-group" :class="{ 'form-group--error': $v.email.$error }">
        <b-form-input type="email" class="form-input form-wide mb-1" v-model.trim="$v.email.$model" id="login-email" >
        </b-form-input>
        <b-form-invalid-feedback class="error" :state="($v.email.email || (!this.$v.email.$dirty))">invalid email address</b-form-invalid-feedback>
        <b-form-invalid-feedback :state="(!this.$v.email.$dirty)||(this.$v.email.required)">
          Email field is required
        </b-form-invalid-feedback>
      </b-form-group>
      <footer class="modal-footer">
        <div class="w-100">
          <b-button variant="link" v-if="!emailSent"  href="#" class="float-left" @click.prevent="$emit('authPageChanged','LOGIN')"> Go to login</b-button>
          <b-button
            variant="primary"
            v-if="!emailSent"
            @click.prevent="requestResetPassword"
            class="float-right">Reset Password</b-button>
          <b-button
            variant="primary"
            v-if="emailSent"
            @click.prevent="requestResetPassword"
            class="float-right">Resend</b-button>
        </div>
      </footer>

    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import RecoverPasswordMixin from './RecoverPassword.mixin'

export default {
  name: 'RecoverPassword',
  mixins: [validationMixin, RecoverPasswordMixin]
}
</script>

<style scoped>

</style>
