import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Blog from '../views/Blog.vue'
import Authentication from '../views/auth/Auth'
import CreateDeal from '../components/deal/CreateDeal'
import ShowDeal from '../components/deal/ShowDeal'
import SearchPage from '../components/search/SearchPage'
import Profile from '../components/user/Profile'
import UsersAdmin from '../components/admin/UsersAdmin'
import DealsAdmin from '../components/admin/DealsAdmin'
import AdminRoot from '../components/admin/AdminRoot'
import EditDeal from '../components/deal/EditDeal'
import Privacy from '../components/about/Privacy'
import CollectionsAdmin from '../components/admin/CollectionsAdmin'
import SlidersAdmin from '../components/admin/SlidersAdmin'
import SliderAdmin from '../components/admin/SliderAdmin'
import CollectionAdmin from '../components/admin/CollectionAdmin'
import StoresList from '../components/stores/StoresList'
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        userOnly: false
      }
    },
    {
      path: '/createDeal',
      name: 'createDeal',
      component: CreateDeal,
      meta: {
        userOnly: true
      }
    },
    {
      path: '/blog',
      name: 'blog',
      component: Blog
    },
    {
      path: '/authenticate',
      name: 'authenticate',
      components: { default: Home, dialog: Authentication }
    },
    {
      path: '/auth',
      name: 'auth',
      component: Home,
      meta: {
        userOnly: false
      },
      children: [
        { name: 'verify_email',
          path: 'verify_email/:accessCode/:userID',
          components: { default: Authentication } },
        { name: 'reset_password',
          path: 'reset_password/:accessCode/:userID',
          components: { default: Authentication } }
      ]
    },
    {
      path: '/admin',
      name: 'admin',
      component: AdminRoot,
      meta: {
        userOnly: true,
        adminOnly: true
      },
      children: [
        { name: 'usersAdmin',
          path: 'users/',
          component: UsersAdmin },
        { name: 'dealsAdmin',
          path: 'deals/',
          component: DealsAdmin },
        { name: 'collectionsAdmin',
          path: 'collections/',
          component: CollectionsAdmin },
        { name: 'slidersAdmin',
          path: 'sliders/',
          component: SlidersAdmin },
        { name: 'sliderAdmin',
          path: 'slider/:id',
          component: SliderAdmin },
        { name: 'collectionAdmin',
          path: 'collection/:id',
          component: CollectionAdmin }
      ]
    },
    { name: 'stores',
      path: '/stores',
      component: StoresList,
      meta: {
        userOnly: false
      }
    },
    {
      path: '/deal/:id',
      name: 'deal',
      component: ShowDeal,
      meta: {
        userOnly: false
      }
    },
    {
      path: '/edit-deal/:id',
      name: 'edit-deal',
      component: EditDeal,
      meta: {
        userOnly: true
      }
    },
    {
      path: '/deals/:category/:store/:keyword',
      name: 'deals',
      component: SearchPage,
      meta: {
        userOnly: false
      }
    },
    {
      path: '/profile/:userID',
      name: 'profile',
      component: Profile,
      meta: {
        userOnly: false
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy,
      meta: {
        userOnly: false
      }
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  ]
})
