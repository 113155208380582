<template>
  <div class="col-md-12">
    <form action="#">
      <b-form-group class="form-group" label-for="login-displayName" label="Display Name" :class="{ 'form-group--error': $v.displayName.$error }">
        <b-form-input type="text" class="form-input form-wide mb-0" v-model.trim="$v.displayName.$model" id="login-displayName" ></b-form-input>
        <b-form-invalid-feedback :state="(!this.$v.displayName.$dirty)||(this.$v.displayName.required)">
          Display name is required
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label-for="login-email" label="Email address" :class="{ 'form-group--error': $v.email.$error }">
        <b-form-input type="email" placeholder="Enter email" class="form-input form-wide mb-2" v-model.trim="$v.email.$model" id="login-email" ></b-form-input>
        <b-form-invalid-feedback class="error" :state="($v.email.email || (!this.$v.email.$dirty))">invalid email address</b-form-invalid-feedback>
        <b-form-invalid-feedback :state="(!this.$v.email.$dirty)||(this.$v.email.required)">
          Email field is required
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Password" label-for="register-password" :class="{ 'form-group--error': $v.password.$error }">
        <!--<tree-view :data="$v.email" :options="{rootObjectKey: '$v.email', maxDepth: 2}"></tree-view>-->
        <b-form-input type="password" class="form-input form-wide mb-0" v-model.trim="$v.password.$model" id="register-password" ></b-form-input>
        <b-form-invalid-feedback :state="($v.password.required || !$v.password.$dirty)">
          Password is required
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="($v.password.minLength || !$v.password.$dirty)">
          Password should be at least {{$v.password.$params.minLength.min}} characters long.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Repeat Password" label-for="repeat-password" class="form-group" :class="{ 'form-group--error': $v.repeatPassword.$error }">
        <b-form-input type="password" v-model.trim="$v.repeatPassword.$model" id="repeat-password" ></b-form-input>
        <b-form-invalid-feedback :state="($v.repeatPassword.required || !$v.repeatPassword.$dirty)">
          Please confirm your password
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="(repeatPassword === password || !$v.repeatPassword.required || !$v.repeatPassword.$dirty)">
          Passwords don't match
        </b-form-invalid-feedback>
        <div :key="index" v-for="(error, index) in errors" class="error">{{error.message}}</div>
      </b-form-group>
      <footer class="modal-footer">
        <b-row class="w-100">
          <b-col class="float-left my-auto px-0">Already have an account ? <a @click.prevent="$emit('authPageChanged','LOGIN')" href="#" class=""> Login</a></b-col>
          <b-button
            variant="primary"
            class="float-right" @click.prevent="signUp">REGISTER</b-button>
        </b-row>
      </footer>
    </form>
  </div>

</template>

<script>
import { validationMixin } from 'vuelidate'
import RegisterMixin from './Register.mixin'

export default {
  name: 'Register',

  mixins: [validationMixin, RegisterMixin]
}
</script>

<style scoped>

</style>
