import { mapActions } from 'vuex'
import he from 'he'

export default {
  created () {
    // this.getFeaturedProducts(this.currentPage)
  },

  data: () => ({
    currentPage: 1
  }),

  watch: {
    currentPage () {
      this.getProducts(this.currentPage)
    }
  },
  mounted: function () {
    this.setHeaderVisibility(true)
  },
  computed: {
    products: {
      get () {
        return this.$store.state.Products.products
      }
    },
    featuredProducts: {
      get () {
        return this.$store.state.Products.featuredProducts
      }
    },
    amountOfFeaturedProducts: {
      get () {
        return this.$store.state.Products.amountOfFeaturedProducts
      }
    },

    amountOfProducts: {
      get () {
        return this.$store.state.Products.amountOfProducts
      }
    }
  },

  methods: {
    encode: text => he.encode(text),

    ...mapActions(['getProducts', 'setHeaderVisibility'])
  }
}
